export const defaultMeterInstallHeaders = [
  {
    id: 'opportunityId',
    label: 'Opportunity ID',
  },
  {
    id: 'jobStatus',
    label: 'Installer Job Status',
  },
  {
    id: 'jobCompletionDate',
    label: 'Completion Date',
  },
  {
    id: 'customerName',
    label: 'Customer',
  },
  {
    id: 'siteLocation',
    label: 'Location',
  },
  {
    id: 'allMetersEvVerified',
    label: 'All Meters EV Verified',
  },
  {
    id: 'meterCount',
    label: 'Meter Count',
  },
  {
    id: 'allMetersAssigned',
    label: 'All Meters Assigned',
  },
]

export const defaultMeterInstallWidths = {
  opportunityId: {
    minWidth: 120,
    maxWidth: 120,
  },
  jobStatus: {
    minWidth: 115,
    maxWidth: 135,
  },
  customerName: {
    minWidth: 180,
    maxWidth: 210,
  },
  jobCompletionDate: {
    minWidth: 260,
    maxWidth: 260,
  },
  isAssignedToSite: {
    minWidth: 120,
    maxWidth: 130,
  },
  siteLocation: {
    minWidth: 220,
    maxWidth: 300,
  },
  allMetersEvVerified: {
    minWidth: 170,
    maxWidth: 225,
  },
  meterCount: {
    minWidth: 100,
    maxWidth: 100,
  },
  allMetersAssigned: {
    minWidth: 100,
    maxWidth: 120,
  },
  modified: {
    minWidth: 230,
    maxWidth: 230,
  },
  panelNames: {
    minWidth: 150,
    maxWidth: 150,
  },
  createdBy: {
    minWidth: 200,
    maxWidth: 200,
  },
  configAcceptedDate: {
    minWidth: 200,
    maxWidth: 200,
  },
  preConfigTestStatus: {
    minWidth: 130,
    maxWidth: 130,
  },
  postConfigTestStatus: {
    minWidth: 150,
    maxWidth: 150,
  },
  evVerified: {
    minWidth: 95,
    maxWidth: 95,
  },
  cellRouterInstalled: {
    minWidth: 150,
    maxWidth: 150,
  },
  isDeleted: {
    minWidth: 100,
    maxWidth: 100,
  },
}

export const breadcrumbs = [
  {
    href: '/reports',
    text: 'Tools & Reports',
  },
  {
    href: '/reports/meter-install-grouped-submissions',
    text: 'Meter Install Submissions',
  },
]

const meterEvVerifiedItems = [
  {
    id: 'Yes',
    name: 'Yes',
  },
  {
    id: 'No',
    name: 'No',
  },
]

const jobStatusItems = [
  {
    id: 'In Progress',
    name: 'In Progress',
  },
  {
    id: 'COMPLETE',
    name: 'Complete',
  },
]
const evVerifiedItems = [
  {
    id: '',
    name: 'All',
  },
  {
    id: 'YES',
    name: 'YES',
  },
  {
    id: 'NO',
    name: 'NO',
  },
]

const postConfigTestStatusItems = [
  {
    id: '',
    name: 'All',
  },
  {
    id: 'PASS',
    name: 'Pass',
  },
  {
    id: 'FAIL',
    name: 'Fail',
  },
  {
    id: 'NO_RESULT',
    name: 'No Result',
  },
]

const preConfigTestStatusItems = [
  {
    id: '',
    name: 'All',
  },
  {
    id: 'PASS',
    name: 'Pass',
  },
  {
    id: 'FAIL',
    name: 'Fail',
  },
  {
    id: 'NO_RESULT',
    name: 'No Result',
  },
]

const isAssignedToSiteItems = [
  {
    id: '',
    name: 'All',
  },
  {
    id: 'YES',
    name: 'YES',
  },
  {
    id: 'NO',
    name: 'NO',
  },
]
const isDeletedItems = [
  {
    id: '',
    name: 'All',
  },
  {
    id: 'true',
    name: 'YES',
  },
  {
    id: 'false',
    name: 'NO',
  },
]

export const dropdownItems = {
  jobStatus: jobStatusItems,
  allMetersEvVerified: meterEvVerifiedItems,
  isAssignedToSite: isAssignedToSiteItems,
  preConfigTestStatus: preConfigTestStatusItems,
  postConfigTestStatus: postConfigTestStatusItems,
  evVerified: evVerifiedItems,
  isDeleted: isDeletedItems,
}

export const submissionHistoryTableHeaders = [
  {
    id: 'modified',
    label: 'Submitted Date',
    sorting: true,
    filtering: false,
  },
  {
    id: 'panelNames',
    label: 'Panel Name',
    sorting: false,
    filtering: true,
  },
  {
    id: 'createdBy',
    label: 'Submitted By',
    sorting: true,
    filtering: true,
  },
  {
    id: 'isAssignedToSite',
    label: 'Assigned to Site',
    sorting: false,
    filtering: false,
  },
  {
    id: 'configAcceptedDate',
    label: 'Config Accepted',
    sorting: false,
    filtering: false,
  },
  {
    id: 'preConfigTestStatus',
    label: 'Pre-Config Tests',
    sorting: true,
    filtering: true,
  },
  {
    id: 'postConfigTestStatus',
    label: 'Post-Config Tests',
    sorting: true,
    filtering: true,
  },
  {
    id: 'evVerified',
    label: 'EV Verified',
    sorting: false,
    filtering: false,
  },
  {
    id: 'cellRouterInstalled',
    label: 'Cell Router Used',
    sorting: false,
    filtering: false,
  },
  {
    id: 'isDeleted',
    label: 'Deleted',
    sorting: false,
    filtering: false,
  },
]
