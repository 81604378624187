import axios from 'axios'
import { combineReducers } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import { consoleApiUrl, defaultHeaders } from '../../api'
import { getTotalPages, handleAxiosError } from '../../api/utils'
import { handleSagaError } from '../../sagas/utils'
import type { FTSortable } from '../../types'
import type { FTEntityMetaSubmission } from '../types'
import { makeApiQueryString } from '../utils'

export type OpportunitySubmissions = {
  opportunityId: string
  jobStatus: string
  customerName: string
  siteLocation: string
  jobCompletionDate: string
  createallMetersAssigneddBy: string
  allMetersEvVerified: string
  meterCount: number
}
type FetchOpportunitySubmissionsResponse = {
  next?: string
  previous?: string
  totalCount: number
  results: Array<OpportunitySubmissions>
}
type FetchOpportunityParams = FTEntityMetaSubmission & {
  opportunityId?: string
  jobStatus?: string
  customerName?: string
  siteLocation?: string
  jobCompletionDate?: string
  createallMetersAssigneddBy?: string
  allMetersEvVerified?: string
  meterCount?: number
  from?: string
  to?: string
  isAssignedToSite?: string
  macAddress?: string
} & { orderBy: FTSortable }

type FetchMeterDataParams = {
  opportunityId: string
  macAddress?: string
  pageSize?: number
} & { orderBy: FTSortable }

type FetchMeterDataByOpportunityParams = FTEntityMetaSubmission & {
  from?: string
  to?: string
  macAddress: string
  opportunityId: string
  isAssignedToSite?: string
  createdBy?: string
  evVerified?: string
  preConfigTestStatus?: string
  postConfigTestStatus?: string
  startConfigAcceptedDate?: string
  endConfigAcceptedDate?: string
  pageSize?: number
  pageNumber?: number
  panelNames?: string
  isDeleted?: string
} & { orderBy: FTSortable }

// Action Types
const types = {
  FETCH_INSTALL_GROUP_SUBMISSIONS: 'FETCH_INSTALL_GROUP_SUBMISSIONS',
  FETCH_INSTALL_GROUP_SUBMISSIONS_SUCCESS:
    'FETCH_INSTALL_GROUP__SUBMISSIONS_SUCCESS',
  FETCH_INSTALL_GROUP_SUBMISSIONS_ERROR:
    'FETCH_INSTALL_GROUP_SUBMISSIONS_ERROR',
  FETCH_METER_DATA: 'FETCH_METER_DATA',
  FETCH_METER_DATA_SUCCESS: 'FETCH_METER_DATA_SUCCESS',
  FETCH_METER_DATA_ERROR: 'FETCH_METER_DATA_ERROR',
  FETCH_METER_DATA_FOR_OPPORTUNITY: 'FETCH_METER_DATA_FOR_OPPORTUNITY',
  FETCH_METER_DATA_FOR_OPPORTUNITY_SUCCESS:
    'FETCH_METER_DATA_FOR_OPPORTUNITY_SUCCESS',
  FETCH_METER_DATA_FOR_OPPORTUNITY_ERROR:
    'FETCH_METER_DATA_FOR_OPPORTUNITY_ERROR',
  RESET_METER_DATA_FOR_OPPORTUNITY: 'RESET_METER_DATA_FOR_OPPORTUNITY',
  RESET_METER_DATA: 'RESET_METER_DATA',
}

const initialState = {
  byId: {},
  items: [],
  meters: {
    data: {},
  },
  metersForOpportunity: {
    data: [],
    meta: {
      loading: false,
      error: '',
      totalCount: 0,
    },
    searchParams: {
      from: '',
      to: '',
      macAddress: '',
      createdBy: '',
      evVerified: '',
      preConfigTestStatus: '',
      postConfigTestStatus: '',
      startConfigAcceptedDate: '',
      endConfigAcceptedDate: '',
      pageSize: 5,
      pageNumber: 1,
      panelNames: '',
      isDeleted: '',
      orderBy: {
        field: 'modified',
        sort: 'DESC',
      },
    },
  },
  orderByMeters: {},
  meta: {
    error: '',
    pageNumber: 1,
    pageSize: 20,
    next: null,
    previous: null,
    loading: false,
    orderBy: {
      field: 'modified',
      sort: 'DESC',
    },
    searchParams: {
      from: '',
      to: '',
      opportunityId: '',
      jobStatus: '',
      customerName: '',
      jobCompletionDate: '',
      isAssignedToSite: '',
      siteLocation: '',
      allMetersEvVerified: '',
      macAddress: '',
    },
  },
}

export const actions = {
  fetchInstallSubmissions: (props: FetchOpportunityParams) => ({
    type: types.FETCH_INSTALL_GROUP_SUBMISSIONS,
    ...props,
  }),
  fetchMeterData: (props: FetchMeterDataParams) => ({
    type: types.FETCH_METER_DATA,
    ...props,
  }),
  fetchMetersForOppurtunity: (props: FetchMeterDataByOpportunityParams) => ({
    type: types.FETCH_METER_DATA_FOR_OPPORTUNITY,
    ...props,
  }),
  resetMeterDataForOpportunity: () => ({
    type: types.RESET_METER_DATA_FOR_OPPORTUNITY,
  }),
  resetMeterData: (props) => ({
    type: types.RESET_METER_DATA,
    ...props,
  }),
}

function entityById(action, state) {
  return { ...state, ...action.payload.result }
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_INSTALL_GROUP_SUBMISSIONS:
      return initialState.byId

    case types.FETCH_INSTALL_GROUP_SUBMISSIONS_SUCCESS:
      return entityById(action, state)

    default:
      return state
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_INSTALL_GROUP_SUBMISSIONS:
      return initialState.items

    case types.FETCH_INSTALL_GROUP_SUBMISSIONS_SUCCESS:
      return action.payload.results

    default:
      return state
  }
}

function meters(state = initialState.meters, action) {
  switch (action.type) {
    case types.FETCH_METER_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.opportunityId]: { loading: true },
        },
      }

    case types.RESET_METER_DATA: {
      const next = { ...state.data }
      delete next[action.opportunityId]
      return { ...state, data: next }
    }

    case types.FETCH_METER_DATA_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      }

    default:
      return state
  }
}

function metersByOpportunity(
  state = initialState.metersForOpportunity,
  action,
) {
  switch (action.type) {
    case types.FETCH_METER_DATA_FOR_OPPORTUNITY:
      return {
        ...state,
        data: [],
        meta: {
          ...state.meta,
          loading: true,
        },
        searchParams: {
          ...action.payload,
        },
      }
    case types.FETCH_METER_DATA_FOR_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        data: [...action.payload.results],
        meta: {
          ...state.meta,
          loading: false,
          totalCount: action.payload.totalCount,
          totalPages: action.payload.totalPages,
          next: action.payload.next,
          previous: action.payload.previous,
        },
        searchParams: {
          ...action.payload.searchParams,
        },
      }
    case types.RESET_METER_DATA_FOR_OPPORTUNITY:
      return initialState.metersForOpportunity
    default:
      return state
  }
}

function orderByMeters(state = initialState.orderByMeters, action) {
  if (action.type.FETCH_METER_DATA_SUCCESS) {
    return {
      ...state,
      [action.payload.opportunityId]: {
        ...action.payload.orderBy,
      },
    }
  }
  return state
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_INSTALL_GROUP_SUBMISSIONS:
      return { ...state, error: '', loading: true }

    case types.FETCH_INSTALL_GROUP_SUBMISSIONS_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.FETCH_INSTALL_GROUP_SUBMISSIONS_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        ...action.payload,
        next: action.payload?.next || null,
        previous: action.payload?.previous || null,
      }
    }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  items,
  meters,
  metersByOpportunity,
  orderByMeters,
  meta,
})

function addMetaToResponse(
  params: FetchOpportunityParams & {
    searchParams: Record<string, any>
  },
  response: Record<string, any>,
) {
  const { pageNumber = 1, pageSize = 20, orderBy, filterBy } = params
  const { data } = response
  const { totalCount = 0 } = data
  const { searchParams } = params
  return {
    ...data,
    totalPages: getTotalPages(totalCount, pageSize),
    pageNumber,
    pageSize,
    orderBy,
    filterBy,
    searchParams,
  }
}

// API
const API = {
  fetchInstallSubmissions(params: FetchOpportunityParams) {
    const {
      opportunityId = '',
      from = '',
      to = '',
      jobStatus = '',
      jobCompletionDate = '',
      allMetersEvVerified = '',
      isAssignedToSite = '',
      customerName = '',
      siteLocation = '',
      macAddress = '',
    } = params

    const searchParams = {
      opportunityId,
      jobStatus,
      customerName,
      jobCompletionDate,
      isAssignedToSite,
      siteLocation,
      allMetersEvVerified,
      from,
      to,
      macAddress,
    }

    const baseUrl = `${consoleApiUrl()}/meter/submission/details/opportunities`
    const query = makeApiQueryString(params, searchParams)
    const url = `${baseUrl}?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) =>
        addMetaToResponse({ ...params, searchParams }, response),
      )
      .catch(handleAxiosError)
  },
  fetchMeterData(
    params: FetchMeterDataParams & FetchMeterDataByOpportunityParams,
  ) {
    const {
      opportunityId = '',
      macAddress = '',
      from = '',
      to = '',
      createdBy = '',
      evVerified = '',
      preConfigTestStatus = '',
      postConfigTestStatus = '',
      startConfigAcceptedDate = '',
      endConfigAcceptedDate = '',
      pageSize = 5,
      pageNumber = 1,
      panelNames = '',
      isDeleted = '',
    } = params
    const searchParams = {
      macAddress,
      from,
      to,
      createdBy,
      evVerified,
      preConfigTestStatus,
      postConfigTestStatus,
      startConfigAcceptedDate,
      endConfigAcceptedDate,
      pageSize,
      pageNumber,
      panelNames,
      isDeleted,
    }
    const baseUrl = `${consoleApiUrl()}/meter/submission/details/${opportunityId}/meters`
    const query = makeApiQueryString(params, searchParams)
    const url = `${baseUrl}?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) =>
        addMetaToResponse({ ...params, searchParams }, response),
      )
      .catch(handleAxiosError)
  },
}

function* fetchInstallGroupSubmissionsSaga(payload: FetchOpportunityParams) {
  try {
    const response: OpportunitySubmissions = yield call(
      API.fetchInstallSubmissions,
      payload,
    )
    yield put({
      type: types.FETCH_INSTALL_GROUP_SUBMISSIONS_SUCCESS,
      payload: response,
    })
  } catch (error) {
    yield handleSagaError(types.FETCH_INSTALL_GROUP_SUBMISSIONS_ERROR, error)
  }
}

function* fetchMeterDataSaga(payload: FetchMeterDataParams) {
  try {
    const response: FetchOpportunitySubmissionsResponse = yield call(
      API.fetchMeterData,
      payload,
    )
    const finalResponse = {
      loading: false,
      [`${payload.opportunityId}`]: response,
      orderBy: payload.orderBy,
      opportunityId: payload.opportunityId,
    }
    yield put({
      type: types.FETCH_METER_DATA_SUCCESS,
      payload: finalResponse,
    })
  } catch (error) {
    yield handleSagaError(types.FETCH_METER_DATA, error)
  }
}

function* fetchMeterDataForOppurtunitySaga(
  payload: FetchMeterDataParams & FetchMeterDataByOpportunityParams,
) {
  try {
    const response: FetchOpportunitySubmissionsResponse = yield call(
      API.fetchMeterData,
      payload,
    )
    const finalResponse = {
      results: [...response.results],
      totalCount: response.totalCount,
      next: response.next,
      previous: response.previous,
      totalPages: getTotalPages(response.totalCount, payload.pageSize || 1),
      searchParams: payload,
    }
    yield put({
      type: types.FETCH_METER_DATA_FOR_OPPORTUNITY_SUCCESS,
      payload: finalResponse,
    })
  } catch (error) {
    yield handleSagaError(types.FETCH_METER_DATA_FOR_OPPORTUNITY_ERROR, error)
  }
}

export const sagas = [
  takeLatest(
    types.FETCH_INSTALL_GROUP_SUBMISSIONS,
    fetchInstallGroupSubmissionsSaga,
  ),
  takeLatest(types.FETCH_METER_DATA, fetchMeterDataSaga),
  takeLatest(
    types.FETCH_METER_DATA_FOR_OPPORTUNITY,
    fetchMeterDataForOppurtunitySaga,
  ),
]

// Selectors
export const selectMeterGroupedSumissions = (state) =>
  state.entities?.meterInstallGroupSubmissions

export const selectMeterTableData = (state) =>
  state.entities?.meterInstallGroupSubmissions.meters

export const selectMeterDataForOpportunity = (state) =>
  state.entities?.meterInstallGroupSubmissions.metersByOpportunity
