import { useCallback, useMemo, useState } from 'react'
import { JSX } from 'react/jsx-runtime'
import { useDispatch, useSelector } from 'react-redux'
import array from 'yup/lib/array'

import RedaptiveReactTable7 from '../../../components/RedaptiveReactTable7'
import Spinner from '../../../components/Spinner'
import {
  actions as dsiActions,
  selectInvoiceData,
} from '../../../ducks/billing/DataSolutionsInvoicing/dsiTableItems'
import MultiSelectFilter from '../BillingThisMonth/components/MultiSelectFilter'
import {
  LoadingSpinnerWrapperStyled,
  NoDataTextWrapper,
} from '../BillingThisMonth/styles'
import { MultiSelectProps } from '../BillingThisMonth/types'
import { ISRUploadStatusDisplayForTable as DSIUploadStatusDisply } from '../ISRFileProcessor/ISRUploadStatusDisplayForTable'
import { LinkStyled, TableWrapper } from '../ISRFileProcessor/styles'

const DataSolutionInvoicingTable = () => {
  const [selectedStatus, setSelectedStatus] = useState<[]>([] as array)
  const dispatch = useDispatch()
  const StatusDisplayCell = useCallback(
    ({
      row: {
        original: { status, createdDate },
      },
    }) => <DSIUploadStatusDisply status={status} uploadDate={createdDate} />,
    [],
  )
  const StatusFilter = useCallback(
    (data: JSX.IntrinsicAttributes & MultiSelectProps) => (
      <MultiSelectFilter
        {...data}
        setSelectedFilter={setSelectedStatus}
        selectedFilter={selectedStatus}
      />
    ),
    [selectedStatus],
  )
  const {
    dsiData,
    dsiDataMeta: { loading, error },
  } = useSelector(selectInvoiceData)
  const handleInvoiceNumberClick = (invoiceNumber) => {
    dispatch(dsiActions.downloadInvoiceById({ invoiceId: invoiceNumber }))
  }
  const InvoiceNumberLinkCell = useCallback(
    ({
      row: {
        original: { invoiceId, status, invoiceNumber },
      },
    }) =>
      status === 'SUCCESS' ?
        <LinkStyled onClick={() => handleInvoiceNumberClick(invoiceId)}>
          {invoiceNumber}
        </LinkStyled>
      : <>{invoiceNumber}</>,
    [],
  )
  const tableData = useMemo(() => {
    if (!loading && !error)
      return [...dsiData].sort(
        (a, b) => new Date(b.createdDate) - new Date(a.createdDate),
      )
    return []
  }, [dsiData])

  const columns = useMemo(
    () => [
      {
        accessor: 'opportunityId',
        Header: 'Opportunity ID',
        hideSettings: false,
        id: 'opportunityId',
        maxWidth: 120,
      },
      {
        accessor: 'invoiceNumber',
        Header: 'Invoice Number',
        hideSettings: false,
        id: 'invoiceNumber',
        maxWidth: 120,
        Cell: InvoiceNumberLinkCell,
      },
      {
        accessor: 'amount',
        Header: 'Amount',
        hideSettings: false,
        id: 'amount',
        maxWidth: 120,
        Cell: ({ value }) => (value ? `$${value}` : '_'),
      },
      {
        accessor: 'createdBy',
        Header: 'Uploaded By',
        hideSettings: false,
        id: 'createdBy',
        maxWidth: 120,
        Cell: ({ value }) => value || '_',
      },
      {
        accessor: 'status',
        Header: 'Status',
        hideSettings: false,
        id: 'status',
        maxWidth: 120,
        Cell: StatusDisplayCell,
        filter: 'includesSome',
        Filter: StatusFilter,
      },
    ],
    [selectedStatus],
  )

  return loading ?
      <LoadingSpinnerWrapperStyled>
        <Spinner />
      </LoadingSpinnerWrapperStyled>
    : <TableWrapper>
        <RedaptiveReactTable7
          globalFilterable={false}
          columns={columns}
          filterable
          data={tableData}
          enablePagination={tableData.length > 0}
        />
        {!tableData.length && !loading && (
          <NoDataTextWrapper>
            Data is not yet available for the selected month. Please upload a
            Data Solutions invoice file to generate the invoices
          </NoDataTextWrapper>
        )}
      </TableWrapper>
}

export default DataSolutionInvoicingTable
