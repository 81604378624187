import moment, { Moment } from 'moment'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'

import Button from './Button'
import CalenderIcon from './Icons/CalendarIcon'
import IonIcon from './IonIcon'
import { colors } from '../utils/themes'

type FTProps = {
  activeMonth: typeof moment
  showMonthPickerDropDown: boolean
  setShowMonthPickerDropDown: any
  onCancel: any
  onSubmit: any
  position?: 'left'
  startMonth?: Moment
  useCurrentMonth?: boolean
}
const MonthPickerStyles = styled.div`
  display: flex;
  justify-content: space-between;

  > div:last-child {
    .ListSelector-Dropdown {
      border-left: 1px solid #e0e0e0;
      padding-left: 15px;
    }
  }
`
const CalendarCaptionStyles = styled.div`
  padding: 18px 20px;
  position: relative;
  text-align: center;
  cursor: pointer;
  display: flex;
  margin: auto;
  justify-content: space-between;
`

const PointerLeftPositionStyles = css`
  left: 100%;
  transform: translateX(50%);
  transform-origin: top;
  rotate: 90deg;
`

const SelectorButtonPointer = styled.div<{
  position?: FTProps['position']
  top?: string
}>`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  bottom: 10px;
  ${({ position }) => position === 'left' && PointerLeftPositionStyles};
  ${({ top }) => top && `top: ${top};`}
`
const SelectedMonthWrapperStyles = styled.div`
  margin: 18px 20px 0;
`
const SelectedMonthStyles = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px 16px;
  text-align: left;
  color: ${colors.blue2};
`
const CalendarTitleStyles = styled.div`
  color: #162447;
  font-family: 'Montserrat';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`
const IonIconLeftStyles = styled.div`
  position: relative;
`
const IonIconRightStyles = styled.div`
  position: relative;
`
const IonIconDisabledStyles = css`
  color: #e0e0e0;
`
const IonIconStyled = styled.div<{ disabled: boolean }>`
  color: #000000;

  button {
    border: none;
    background-color: transparent;
    position: relative;
    height: 25px;
    cursor: pointer;
  }
  .ion-chevron-left {
    position: inherit;
    ${({ disabled }) => disabled && IonIconDisabledStyles};
  }

  .ion-chevron-right {
    position: inherit;
    ${({ disabled }) => disabled && IonIconDisabledStyles};
  }
`

const DEFAULT_CALENDER_WIDTH = 308
const DEFAULT_CALENDER_HEIGHT = 392

const CalenderWrapperStyles = styled.div<{
  left: number
  top: number
}>`
  width: ${DEFAULT_CALENDER_WIDTH}px;
  height: ${DEFAULT_CALENDER_HEIGHT}px;
  position: fixed;
  z-index: 1;
  right: 420px;
  ${({ left }) => (left ? `left:${left}px;` : 'right:420px;')}
  ${({ top }) => (top ? `top:${top}px;` : 'top:407px;')}
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 6px rgb(0 0 0 / 8%);
  margin-top: 12px;
`
const CalendarMonthGridItemActiveStyles = css`
  background-color: #485da0;
  color: #ffffff;
`
const CalendarMonthGridItemRangeStyles = css`
  background-color: #485da0;
  color: #ffffff;
  opacity: 0.7;
`
const CalendarMonthGridItemDisabledStyles = css`
  background-color: #f9f9f9;
  color: #000000;
  cursor: unset;

  &:hover {
    background-color: #f9f9f9;
    color: #000000;
    opacity: 1;
  }
`
const CalendarMonthGridItemStyled = styled.div<{
  disabled: boolean
  active: boolean
}>`
  background-color: #e0e0e0;
  border-radius: 4px;
  color: #000000;
  cursor: pointer;
  font-size: 14px;
  height: 58px;
  line-height: 50px;
  text-align: center;
  width: 58px;

  &:hover {
    background-color: #485da0;
    color: #ffffff;
    opacity: 0.7;
  }

  ${({ active }) => active && CalendarMonthGridItemActiveStyles};
  ${({ disabled }) => disabled && CalendarMonthGridItemDisabledStyles};
`
const CalendarMonthGridStyles = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, minmax(58px, 0));
  padding: 0 23px;
`
export const MonthSelectorButton = styled(Button)`
  align-items: center;
  background-color: #fff;
  color: ${colors.blue2};
  display: flex;
  justify-content: space-between;
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  height: 36px;
  letter-spacing: normal;
  padding: 0 16px;
  width: 160px;
  text-transform: none;
  border: 1px solid ${colors.blue2};
  border-radius: 4px;
`
const DateButtonsStyles = styled.div`
  position: absolute;
  bottom: 15px;
  right: 18px;
  z-index: 1;

  button {
    background-color: #9dbd79;
    border-radius: 4px;
    border: none;
    color: #fff;
    height: 36px;
    margin: 0 5px;
    width: 72px;

    &:hover {
      background-color: #f2a91c;
      cursor: pointer;
    }

    &:first-child {
      background-color: #e0e0e0;
      color: #6c6d6e;
      font-family: Montserrat;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;

      &:hover {
        background-color: #818b96;
        color: #fff;
      }
    }
  }
`
const PickerBgLayer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #00000054;
  z-index: 0;
`

const monthsList = moment.monthsShort()
export default (props: FTProps) => {
  const {
    activeMonth,
    showMonthPickerDropDown,
    setShowMonthPickerDropDown,
    onCancel,
    onSubmit,
    position,
    startMonth,
    useCurrentMonth = false,
  } = props
  const [selectedMonth, setSelectedMonth] = useState(activeMonth)
  const [selectedMonthYear, setSelectedMonthYear] = useState(
    moment(activeMonth).format('YYYY'),
  )
  const [monthSelectorPosition, setMonthSelectorPosition] = useState({
    left: 0,
    top: 0,
  })
  const monthPickerRef = useRef<HTMLDivElement>(null)
  const calenderBoxRef = useRef<HTMLDivElement>(null)

  const onMonthChange = (month: typeof moment) => {
    setSelectedMonth(month)
  }

  const renderCalendarMonthGrid = ({ year }: { year: string }) => {
    const getItemProperties = (month) => {
      const active =
        moment(selectedMonth).format('MMM YYYY') === `${month} ${year}`
      const isDisabled =
        startMonth ?
          moment(`${month} ${year}`, 'MMMM YYYY').isBefore(startMonth)
        : false

      return {
        active,
        disabled: isDisabled,
      }
    }

    const handleGridItemClick = ({
      target: {
        dataset: { month },
      },
    }: any) => {
      const isValidMonth =
        useCurrentMonth ?
          moment(`${month} ${year}`, 'MMMM YYYY').isSameOrBefore(
            moment(),
            'month',
          )
        : moment(`${month} ${year}`, 'MMMM YYYY').isBefore(moment(), 'month')

      if (isValidMonth) {
        onMonthChange(moment(`${month} ${year}`, 'MMMM YYYY'))
      }
    }

    return monthsList.map((month) => {
      const { active, disabled } = getItemProperties(month)
      return (
        <CalendarMonthGridItemStyled
          active={active}
          disabled={disabled}
          key={month}
          data-disabled={disabled}
          data-month={month}
          onClick={handleGridItemClick}
        >
          {month}
        </CalendarMonthGridItemStyled>
      )
    })
  }

  const handlePreviousIconClick = (event: any) => {
    const isDisabled = event.currentTarget.getAttribute('data-disabled')

    if (isDisabled === 'true') {
      return
    }

    setSelectedMonthYear(
      moment(selectedMonthYear).subtract(1, 'years').format('YYYY'),
    )
  }

  const handleNextIconClick = (event: any) => {
    const isDisabled = event.currentTarget.getAttribute('data-disabled')

    if (isDisabled === 'true') {
      return
    }

    setSelectedMonthYear(
      moment(selectedMonthYear).add(1, 'years').format('YYYY'),
    )
  }

  const disableScrollOnTheApp = () => {
    // $FlowFixMe
    document.body.style.overflow = 'hidden'
  }

  const enableScrollOnTheApp = () => {
    // $FlowFixMe
    document.body.style.overflow = 'unset'
  }

  const handleMonthSelectorButtonClick = () => {
    setShowMonthPickerDropDown(!showMonthPickerDropDown)
    disableScrollOnTheApp()
  }

  const handleCancel = () => {
    setSelectedMonthYear(moment(activeMonth).format('YYYY'))
    onCancel()
    enableScrollOnTheApp()
    setSelectedMonth(activeMonth)
  }

  const handleSubmit = () => {
    onSubmit(selectedMonth)
    enableScrollOnTheApp()
  }

  const renderIonIcon = ({
    // year,
    direction,
  }: {
    year: string
    direction: string
  }) => {
    const handleClick =
      direction === 'left' ? handlePreviousIconClick : handleNextIconClick
    return (
      <IonIconStyled disabled={false}>
        <button type='button' data-disabled={false} onClick={handleClick}>
          <IonIcon iconClass={`ion-chevron-${direction}`} />
        </button>
      </IonIconStyled>
    )
  }

  const getPointerTop = () => {
    const rect = monthPickerRef.current?.getBoundingClientRect()
    const calendarBoxRect = calenderBoxRef.current?.getBoundingClientRect()
    if (!rect || !calendarBoxRect) return 'unset'

    const top = Math.abs(rect.top - calendarBoxRect.top) + 10

    return position === 'left' ? `${top}px` : 'unset'
  }

  useEffect(() => {
    // $FlowFixMe
    const rect = monthPickerRef.current?.getBoundingClientRect()
    const calendarBoxRect = calenderBoxRef.current?.getBoundingClientRect()
    const calendarWidth = calendarBoxRect?.width || DEFAULT_CALENDER_WIDTH
    const calendarHeight = calendarBoxRect?.height || DEFAULT_CALENDER_HEIGHT

    if (rect && monthPickerRef.current) {
      // $FlowFixMe
      let left = rect.left - monthPickerRef.current.offsetWidth / 2

      if (left + calendarWidth > window.innerWidth) {
        left = window.innerWidth - calendarWidth
      }

      if (position === 'left') {
        left = rect.left - calendarWidth - 10
      }

      let top = rect.bottom

      if (top + calendarHeight > window.innerHeight) {
        top = window.innerHeight - calendarHeight
      }

      setMonthSelectorPosition({
        left,
        top,
      })
    }
  }, [window.innerWidth, showMonthPickerDropDown])
  return (
    <MonthPickerStyles>
      <MonthSelectorButton
        ref={monthPickerRef}
        onClick={handleMonthSelectorButtonClick}
      >
        {moment(activeMonth).format('MMMM YYYY')}
        <CalenderIcon color={colors.orange2} />
      </MonthSelectorButton>
      {showMonthPickerDropDown &&
        createPortal(
          <>
            <PickerBgLayer />
            <CalenderWrapperStyles
              ref={calenderBoxRef}
              left={monthSelectorPosition.left}
              top={monthSelectorPosition.top}
            >
              <SelectorButtonPointer
                position={position}
                top={getPointerTop()}
              />
              <SelectedMonthWrapperStyles>
                <SelectedMonthStyles>
                  {moment(selectedMonth).format('MMMM YYYY')}
                </SelectedMonthStyles>
              </SelectedMonthWrapperStyles>
              <CalendarCaptionStyles>
                <IonIconLeftStyles>
                  {renderIonIcon({
                    year: selectedMonthYear,
                    direction: 'left',
                  })}
                </IonIconLeftStyles>
                <CalendarTitleStyles>
                  <strong>{selectedMonthYear}</strong>
                </CalendarTitleStyles>
                <IonIconRightStyles>
                  {renderIonIcon({
                    year: selectedMonthYear,
                    direction: 'right',
                  })}
                </IonIconRightStyles>
              </CalendarCaptionStyles>
              <CalendarMonthGridStyles>
                {renderCalendarMonthGrid({
                  year: selectedMonthYear,
                })}
              </CalendarMonthGridStyles>
              {showMonthPickerDropDown && (
                <DateButtonsStyles>
                  <button type='button' onClick={handleCancel}>
                    Cancel
                  </button>
                  <button type='button' onClick={handleSubmit}>
                    Apply
                  </button>
                </DateButtonsStyles>
              )}
            </CalenderWrapperStyles>
          </>, // location of portal container
          // $FlowFixMe
          document?.querySelector('.portalWrapper .portalContentContainer'),
        )}
    </MonthPickerStyles>
  )
}
