import { FTPanel, FTPanelSummary } from './panels'
import { FTEntity } from './types'
import type { FTProps as FTEntityEditorProps } from '../components/EntityEditorModal'
import type { FTModalKfactorProps } from '../components/GasMeterConfiguration/KFactorModal/commons'
import { FTModalConfigHistoryProps } from '../components/MeterConfigHistory'
import type { FTProps as FTModalPanelListEditorProps } from '../components/ModalPanelListEditor'
import type { FTModalLockUnlock } from '../components/ProposalsEngine/ModalLockUnlock'
import type { FTShowModalScenario } from '../components/ProposalsEngine/ModalScenario'
import type { FTModalSeekMargin } from '../components/ProposalsEngine/ModalSeekMargin'
import { FTModalMeterSubmissionHistoryProps } from '../pages/MeterInstallGroupedSubmissions/MeterSubmissionHistoryModal'

type FTModalTypes =
  | 'MODAL_BILLING_SEND_TO_VERIFICATION'
  | 'MODAL_BILLING_RE_SEND_TO_VERIFICATION'
  | 'MODAL_BILLING_SEND_TO_REVIEW'
  | 'MODAL_BILLING_SEND_TO_REVIEW_BULK'
  | 'MODAL_BILLING_SEND_TO_INVESTIGATION'
  | 'MODAL_BILLING_SEND_TO_NETSUITE'
  | 'MODAL_CONFIRM'
  | 'MODAL_CONFIRM2'
  | 'MODAL_ELECTRON_VERIFIED_FORM'
  | 'MODAL_FIELD_STATUS_FORM'
  | 'MODAL_ENTITY_EDITOR'
  | 'MODAL_GENERIC'
  | 'MODAL_LOADING'
  | 'MODAL_PANEL_DELETE_CONFLICT'
  | 'MODAL_PANEL_FORM'
  | 'MODAL_PANEL_FORM2'
  | 'MODAL_PANEL_FORM3'
  | 'MODAL_PANEL_LIST_EDITOR'
  | 'MODAL_RESET_PASSWORD_LINK'
  | 'MODAL_SCENARIO'
  | 'MODAL_SEEK_MARGIN'
  | 'MODAL_SERVER_ERROR'
  | 'MODAL_ENERGY_STAR'
  | 'MODAL_BILLING_RERUN_SAVINGS'
  | 'MODAL_LOCK_UNLOCK'
  | 'MODAL_SIGNUP_LINK'
  | 'MODAL_BILLING_REMAINING_QUANTITY'
  | 'MODAL_KFACTOR_BASE'
  | 'MODAL_METER_CONTRACT_ASSOCIATION'
  | 'MODAL_CONFIG_HISTORY'
  | 'MODAL_METER_SUBMISSION_HISTORY'
  | 'MODAL_UTILITY_DATA'
  | 'MODAL_ADD_SCENARIO_WITHOUT_SALES_TAX'
  | 'MODAL_PANEL_PHOTOS_VIEW'
  | 'MODAL_UPLOAD_CUSTOMER_LOGO'
  | 'MODAL_DELETE_CUSTOMER_LOGO'

export type FTCreateModalParams = {
  modalType: FTModalTypes
  modalProps: Record<string, any>
}
type FTState = {
  modalType: FTModalTypes | null | undefined
  modalProps: Record<string, any> | null | undefined
}
type FTShowModal = {
  type: 'SHOW_MODAL'
} & FTState
const initialState = {
  modalType: null,
  modalProps: null,
}
export const modalTypes = {
  MODAL_BILLING_SEND_TO_VERIFICATION: 'MODAL_BILLING_SEND_TO_VERIFICATION',
  MODAL_BILLING_RE_SEND_TO_VERIFICATION:
    'MODAL_BILLING_RE_SEND_TO_VERIFICATION',
  MODAL_BILLING_SEND_TO_REVIEW: 'MODAL_BILLING_SEND_TO_REVIEW',
  MODAL_BILLING_SEND_TO_REVIEW_BULK: 'MODAL_BILLING_SEND_TO_REVIEW_BULK',
  MODAL_BILLING_SEND_TO_INVESTIGATION: 'MODAL_BILLING_SEND_TO_INVESTIGATION',
  MODAL_BILLING_SEND_TO_NETSUITE: 'MODAL_BILLING_SEND_TO_NETSUITE',
  MODAL_CONFIRM: 'MODAL_CONFIRM',
  MODAL_CONFIRM2: 'MODAL_CONFIRM2',
  MODAL_ELECTRON_VERIFIED_FORM: 'MODAL_ELECTRON_VERIFIED_FORM',
  MODAL_FIELD_STATUS_FORM: 'MODAL_FIELD_STATUS_FORM',
  MODAL_ENTITY_EDITOR: 'MODAL_ENTITY_EDITOR',
  MODAL_GENERIC: 'MODAL_GENERIC',
  MODAL_LOADING: 'MODAL_LOADING',
  MODAL_PANEL_DELETE_CONFLICT: 'MODAL_PANEL_DELETE_CONFLICT',
  MODAL_PANEL_FORM: 'MODAL_PANEL_FORM',
  MODAL_PANEL_FORM2: 'MODAL_PANEL_FORM2',
  MODAL_PANEL_FORM3: 'MODAL_PANEL_FORM3',
  MODAL_PANEL_LIST_EDITOR: 'MODAL_PANEL_LIST_EDITOR',
  MODAL_RESET_PASSWORD_LINK: 'MODAL_RESET_PASSWORD_LINK',
  MODAL_SIGNUP_LINK: 'MODAL_SIGNUP_LINK',
  MODAL_SERVER_ERROR: 'MODAL_SERVER_ERROR',
  MODAL_SCENARIO: 'MODAL_SCENARIO',
  MODAL_LOCK_UNLOCK: 'MODAL_LOCK_UNLOCK',
  MODAL_BILLING_REMAINING_QUANTITY: 'MODAL_BILLING_REMAINING_QUANTITY',
  MODAL_SEEK_MARGIN: 'MODAL_SEEK_MARGIN',
  MODAL_ENERGY_STAR: 'MODAL_ENERGY_STAR',
  MODAL_BILLING_RERUN_SAVINGS: 'MODAL_BILLING_RERUN_SAVINGS',
  MODAL_KFACTOR_BASE: 'MODAL_KFACTOR_BASE',
  MODAL_METER_CONTRACT_ASSOCIATION: 'MODAL_METER_CONTRACT_ASSOCIATION',
  MODAL_CONFIG_HISTORY: 'MODAL_CONFIG_HISTORY',
  MODAL_METER_SUBMISSION_HISTORY: 'MODAL_METER_SUBMISSION_HISTORY',
  MODAL_UTILITY_DATA: 'MODAL_UTILITY_DATA',
  MODAL_ADD_SCENARIO_WITHOUT_SALES_TAX: 'MODAL_ADD_SCENARIO_WITHOUT_SALES_TAX',
  MODAL_PANEL_PHOTOS_VIEW: 'MODAL_PANEL_PHOTOS_VIEW',
  MODAL_UPLOAD_CUSTOMER_LOGO: 'MODAL_UPLOAD_CUSTOMER_LOGO',
  MODAL_DELETE_CUSTOMER_LOGO: 'MODAL_DELETE_CUSTOMER_LOGO',
}

export const types = {
  HIDE_MODAL: 'HIDE_MODAL',
  SHOW_MODAL: 'SHOW_MODAL',
}

function createModalAction({
  modalType,
  modalProps = {},
}: FTCreateModalParams) {
  if (!modalTypes[modalType]) {
    throw new Error(`Unknown modal type: ${modalType}`)
  }

  return {
    type: types.SHOW_MODAL,
    modalType,
    modalProps,
  }
}
type CustomizedErrorBodyAction = {
  customizedErrorBody?: string | React.ReactNode
  noErrorAction?: boolean | React.ReactNode
}
const showServerError = (modalProps: CustomizedErrorBodyAction) =>
  createModalAction({
    modalType: modalTypes.MODAL_SERVER_ERROR,
    modalProps,
  })

export type FTModalPanelForm = {
  conditionalFields?: Object
  description?: string
  id?: string
  name?: string
  siteId: string
  meterGeneration: string
  type?: string
  voltage?: string
  parentPanelId?: string
  panelLevel?: string
  panelsById?: Object
  allPanels: Array<FTPanel>
}

export type FTModalPanelForm2 = {
  conditionalFields?: Object
  description?: string
  id?: string
  name?: string
  siteId: string
  meterIds?: Array<string>
  type?: string
  voltage?: string
  location?: string
  amperage?: string
  parentPanelId?: string
  panelLevel?: string
  panelsById?: Object
}

export type FTModalPanelForm3 = {
  siteId: string
  panelListEntity?: FTEntity
  panelById?: FTPanelSummary
  circuitsById?: Record<string, any>
  meterIds?: string[]
  closeModal: (...args: Array<any>) => any
  updateMode?: boolean
  handleSuccess: (panelId: string) => void
  editMode?: boolean
}

const showModalPanelForm = (modalProps: FTModalPanelForm) =>
  createModalAction({
    modalType: modalTypes.MODAL_PANEL_FORM,
    modalProps,
  })

const showModalPanelForm2 = (modalProps: FTModalPanelForm2) =>
  createModalAction({
    modalType: modalTypes.MODAL_PANEL_FORM2,
    modalProps,
  })

const showModalPanelForm3 = (modalProps: FTModalPanelForm3) =>
  createModalAction({
    modalType: modalTypes.MODAL_PANEL_FORM3,
    modalProps,
  })

export type FTModalElectronVerified = {
  id: string
  verified: boolean
  verifiedDate: string
}
export type FTModalFieldStatus = {
  id: string
  active: boolean
  deactivationReason: string
  deactivationDate: string
}

export type FTModalContractAssociation = {
  id: string
  opportunities: Array<any>
}

const showModalElectronVerifiedForm = (modalProps: FTModalElectronVerified) =>
  createModalAction({
    modalType: modalTypes.MODAL_ELECTRON_VERIFIED_FORM,
    modalProps,
  })

const showModalFieldStatusForm = (modalProps: FTModalFieldStatus) =>
  createModalAction({
    modalType: modalTypes.MODAL_FIELD_STATUS_FORM,
    modalProps,
  })

const showModalMeterContractAssociationForm = (
  modalProps: FTModalContractAssociation,
) =>
  createModalAction({
    modalType: modalTypes.MODAL_METER_CONTRACT_ASSOCIATION,
    modalProps,
  })

export type FTConfirmModal = {
  primaryActionText: string
  onPrimaryAction?: (...args: Array<any>) => any
  secondaryActionText?: string
  onSecondaryAction?: (...args: Array<any>) => any
  content?: Array<string>
  renderBody?: () => React.ReactNode
  renderContent?: () => React.ReactNode
  modalWidth?: string
}

const showConfirmModal = (modalProps: FTConfirmModal) => {
  const {
    primaryActionText,
    onPrimaryAction,
    secondaryActionText,
    onSecondaryAction,
    ...remaining
  } = modalProps
  return createModalAction({
    modalType: modalTypes.MODAL_CONFIRM,
    modalProps: {
      ...remaining,
      onAccept: onPrimaryAction,
      onCancel: onSecondaryAction,
      declineText: secondaryActionText,
      acceptText: primaryActionText,
      hideClose: true,
    },
  })
}

export type FTModalConfirm2 = {
  handlePrimaryAction: (...args: Array<any>) => any
  handleSecondaryAction?: (...args: Array<any>) => any
  loading: boolean
  primaryText?: string
  renderBody: (...args: Array<any>) => any
  renderTitle: (...args: Array<any>) => any
  secondaryText?: string
  showSecondaryButton?: boolean
}

const showModalConfirm2 = (modalProps: FTModalConfirm2) =>
  createModalAction({
    modalType: modalTypes.MODAL_CONFIRM2,
    modalProps,
  })

export type FTBillingSendToVerificationModal = {
  billId: Array<string>
  billingSites: Array<Record<string, any>>
  setSendToVerificationSubmitted: (...args: Array<any>) => any
}

const showBillingSendToVerificationModal = (
  modalProps: FTBillingSendToVerificationModal,
) =>
  createModalAction({
    modalType: modalTypes.MODAL_BILLING_SEND_TO_VERIFICATION,
    modalProps,
  })

export type FTBillingReSendToVerificationModal = {
  savingsMonth: string
  opportunityIds: Array<string>
  setResendToVerificationSubmitted: (...args: Array<any>) => any
}

const showBillingReSendToVerificationModal = (
  modalProps: FTBillingReSendToVerificationModal,
) =>
  createModalAction({
    modalType: modalTypes.MODAL_BILLING_RE_SEND_TO_VERIFICATION,
    modalProps,
  })

export type FTBillingSendToReviewModal = {
  billId: string
  selectedAction: string
  setSendToReviewSubmitted: (...args: Array<any>) => any
}

const showBillingSendToReviewModal = (modalProps: FTBillingSendToReviewModal) =>
  createModalAction({
    modalType: modalTypes.MODAL_BILLING_SEND_TO_REVIEW,
    modalProps,
  })

export type FTBillingSendToReviewBulkModal = {
  selectedAction: string
  isSelectedActionValid: boolean
  allowOpportunities: Array<any>
  notAllowOpportunities: Array<any>
  setSendToReviewBulkSubmitted: (...args: Array<any>) => any
}

const showBillingSendToReviewBulkModal = (
  modalProps: FTBillingSendToReviewBulkModal,
) =>
  createModalAction({
    modalType: modalTypes.MODAL_BILLING_SEND_TO_REVIEW_BULK,
    modalProps,
  })

export type FTBillingSendToInvestigationModal = {
  billIds: string
  reassigned: boolean
  issueOwners: string
  setSendToInvestigationSubmitted: (...args: Array<any>) => any
}

const showBillingSendToInvestigationModal = (
  modalProps: FTBillingSendToInvestigationModal,
) =>
  createModalAction({
    modalType: modalTypes.MODAL_BILLING_SEND_TO_INVESTIGATION,
    modalProps,
  })

export type FTBillingSendToNetsuiteModal = {
  savingsMonth: string
  opportunityList: Array<string>
  setSendToNetsuiteSubmitted: (...args: Array<any>) => any
}

const showBillingSendToNetsuiteModal = (
  modalProps: FTBillingSendToNetsuiteModal,
) =>
  createModalAction({
    modalType: modalTypes.MODAL_BILLING_SEND_TO_NETSUITE,
    modalProps,
  })

const showLoadingModal = () =>
  createModalAction({
    modalType: modalTypes.MODAL_LOADING,
    modalProps: {
      isLoading: true,
      title: 'Saving Changes...',
      hideClose: true,
      modalWidth: '418px',
    },
  })

export type FTModalPanelDeleteConflict = {
  panelName: string
  meters: Array<{
    id: string
    name: string
  }>
}
export type FTModalResetPasswordLink = {
  email: string
}

const showModalResetPasswordLink = (modalProps: FTModalResetPasswordLink) =>
  createModalAction({
    modalType: modalTypes.MODAL_RESET_PASSWORD_LINK,
    modalProps,
  })

export type FTModalSignupLink = {
  email: string
  id: string
}

const showModalSignupLink = (modalProps: FTModalSignupLink) =>
  createModalAction({
    modalType: modalTypes.MODAL_SIGNUP_LINK,
    modalProps,
  })

export type FTModalGeneric = {
  title: string | React.ReactNode
  body?: string | React.ReactNode
  acceptText?: string
  handleClose?: () => void
}

const showModalGeneric = (modalProps: FTModalGeneric) =>
  createModalAction({
    modalType: modalTypes.MODAL_GENERIC,
    modalProps,
  })

export type FTReRunSavingsModal = {
  title: string
  subTitle: string
  savingsMonth: string
  isBulkProcess: boolean
  billingSites: Array<Record<string, any>>
  setReRunSavingsSubmitted: (...args: Array<any>) => any
}

export type FTAddScenarioWithoutSalesTaxModal = {
  warningMsg?: Function
  closeModal: Function
  body?: Function
  onSubmit?: Function
}

export type DeleteLogoModalProps = {
  confirmText: string
  cancelText: string
  handleConfirm: (...args: Array<any>) => any
  handleCancel: (...args: Array<any>) => any
  renderBody: (...args: Array<any>) => any
}

const showBillingReRunSavingsModal = (modalProps: FTReRunSavingsModal) =>
  createModalAction({
    modalType: modalTypes.MODAL_BILLING_RERUN_SAVINGS,
    modalProps,
  })

const showModalPanelDeleteConflict = (modalProps: FTModalPanelDeleteConflict) =>
  createModalAction({
    modalType: modalTypes.MODAL_PANEL_DELETE_CONFLICT,
    modalProps,
  })

const showModalPanelPhotosView = () =>
  createModalAction({
    modalType: modalTypes.MODAL_PANEL_PHOTOS_VIEW,
  })

const showEntityEditorModal = (modalProps: FTEntityEditorProps) => ({
  type: types.SHOW_MODAL,
  modalType: modalTypes.MODAL_ENTITY_EDITOR,
  modalProps,
})

const showModalPanelListEditor = (modalProps: FTModalPanelListEditorProps) => ({
  type: types.SHOW_MODAL,
  modalType: modalTypes.MODAL_PANEL_LIST_EDITOR,
  modalProps,
})

const showModalScenario = (modalProps: FTShowModalScenario) =>
  createModalAction({
    modalType: modalTypes.MODAL_SCENARIO,
    modalProps,
  })

const showModalLockUnlock = (modalProps: FTModalLockUnlock) =>
  createModalAction({
    modalType: modalTypes.MODAL_LOCK_UNLOCK,
    modalProps,
  })

const showUtilityDataModal = (modalProps: any) =>
  createModalAction({
    modalType: modalTypes.MODAL_UTILITY_DATA,
    modalProps,
  })

const showModalKfactorBase = (modalProps: FTModalKfactorProps) =>
  createModalAction({
    modalType: modalTypes.MODAL_KFACTOR_BASE,
    modalProps,
  })

const showModalConfigHistory = (modalProps: FTModalConfigHistoryProps) =>
  createModalAction({
    modalType: modalTypes.MODAL_CONFIG_HISTORY,
    modalProps,
  })

const showModalMeterSubmissionHistory = (
  modalProps: FTModalMeterSubmissionHistoryProps,
) =>
  createModalAction({
    modalType: modalTypes.MODAL_METER_SUBMISSION_HISTORY,
    modalProps,
  })

const showGoalSeekModal = (modalProps: FTModalSeekMargin) =>
  createModalAction({
    modalType: modalTypes.MODAL_SEEK_MARGIN,
    modalProps,
  })

const showEnergyStarSubmissionModal = (modalProps: {
  closeModal: (...args: Array<any>) => any
}) =>
  createModalAction({
    modalType: modalTypes.MODAL_ENERGY_STAR,
    modalProps,
  })

const showModalAddScenarioWithoutSalesTax = (
  modalProps: FTAddScenarioWithoutSalesTaxModal,
) =>
  createModalAction({
    modalType: modalTypes.MODAL_ADD_SCENARIO_WITHOUT_SALES_TAX,
    modalProps,
  })

const hideModal = () => ({ type: types.HIDE_MODAL })

export type FTBillingRemainingQuantityModal = {
  onPrimaryAction?: ((...args: Array<any>) => any) | null | undefined
  onSecondaryAction?: ((...args: Array<any>) => any) | null | undefined
}

const showBillingRemainingQuantityModal = (
  modalProps: FTBillingRemainingQuantityModal,
) =>
  createModalAction({
    modalType: modalTypes.MODAL_BILLING_REMAINING_QUANTITY,
    modalProps,
  })

const showModalUploadCustomerLogo = (modalProps: { customerId: string }) =>
  createModalAction({
    modalType: modalTypes.MODAL_UPLOAD_CUSTOMER_LOGO,
    modalProps,
  })

const showModalDeleteCustomerLogo = (modalProps: DeleteLogoModalProps) =>
  createModalAction({
    modalType: modalTypes.MODAL_DELETE_CUSTOMER_LOGO,
    modalProps,
  })

export const actions = {
  hideModal,
  showBillingSendToVerificationModal,
  showBillingSendToReviewModal,
  showBillingSendToReviewBulkModal,
  showBillingReSendToVerificationModal,
  showBillingSendToInvestigationModal,
  showBillingSendToNetsuiteModal,
  showBillingReRunSavingsModal,
  showConfirmModal,
  showModalConfirm2,
  showEntityEditorModal,
  showLoadingModal,
  showModalElectronVerifiedForm,
  showModalFieldStatusForm,
  showModalMeterContractAssociationForm,
  showModalGeneric,
  showModalPanelDeleteConflict,
  showModalPanelForm,
  showModalPanelForm2,
  showModalPanelForm3,
  showModalPanelListEditor,
  showModalResetPasswordLink,
  showModalScenario,
  showGoalSeekModal,
  showModalSignupLink,
  showServerError,
  showEnergyStarSubmissionModal,
  showModalLockUnlock,
  showModalKfactorBase,
  showModalConfigHistory,
  showModalMeterSubmissionHistory,
  showBillingRemainingQuantityModal,
  showUtilityDataModal,
  showModalAddScenarioWithoutSalesTax,
  showModalPanelPhotosView,
  showModalUploadCustomerLogo,
  showModalDeleteCustomerLogo,
}

export default (state: FTState = initialState, action: FTShowModal) => {
  switch (action.type) {
    case types.SHOW_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      }

    case types.HIDE_MODAL:
      return initialState

    default:
      return state
  }
}
