import { queryStringify, handleAxiosError } from '../api/utils'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { consoleBaseUrl, defaultHeaders } from '../api'

// API Calls
const updatedData = (data) =>
  data?.map((item) => {
    const dateFields = [
      'externalCreatedDate',
      'externalLastUsageDate',
      'externalEarliestCalibrationDate',
      'lastDataIngestionDate',
      'externalLastCommunicationDate',
      'externalLastCalibrationDate',
    ]
    const updatedItem = { ...item }
    dateFields.forEach((field) => {
      updatedItem[field] =
        item[field] ?
          `${moment(item[field]).format('MM/DD/YYYY, hh:mm:ss A')} UTC`
        : null
    })
    return updatedItem || []
  })

const fetchSiteReadiness = async () => {
  const query = queryStringify({
    pageSize: 1000,
  })
  const url = `${consoleBaseUrl()}/api/water-meter-tool/sites/external-site-readiness/list?${query}`
  return axios
    .get(url, {
      headers: defaultHeaders(),
    })
    .then(({ data }) => updatedData(data?.results))
    .catch(handleAxiosError)
}

// Queries
export const useSiteReadiness = () =>
  useQuery({
    queryKey: ['site-readiness'],
    queryFn: () => fetchSiteReadiness(),
  })
