import styled from 'styled-components'

import { colors } from '../../../utils/themes'

export const InputWrapper = styled.div`
  display: flex;
  justify-content: end;
  height: 36%;
  position: relative;
  margin: 10px 0;
  input[type='text'] {
    padding-left: 10px;
  }
`
export const Input = styled.input`
  width: 254px;
  height: 100%;
  padding: 9px 0;
  border: 1px solid ${colors.gray8};
  color: ${colors.gray2};
  border-radius: 4px;
  &:focus {
    outline: none;
    border: 1px solid ${colors.gray8};
  }
`

export const SearchClear = styled.div`
  position: absolute;
  right: 10px;
  top: 9px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #337ab7;
  cursor: pointer;
  ::placeholder {
    color: ${colors.gray2};
  }
`
