import FeatureValidator from '../../authorization/components/FeatureValidator'
import AuthorizedFeatures from '../../authorization/features'
import ActionCard from '../../components/ActionCard'
import ActionCardsPage from '../../components/ActionCardsPage'
import userHasPermission from '../../hooks/userHasPermission'
import type { FTWithRouter } from '../../types'
import { GROUPS } from '../../utils/constants'

type FTProps = FTWithRouter

const BillingMainPage = (props: FTProps) => {
  const {
    history,
    match: { path },
  } = props

  const navigateThisMonth = () => history.push(`${path}/this-month`)
  const navigateISRProcessor = () => history.push(`${path}/isr-file-processor`)
  const navigateDataSolutionsInvoicing = () =>
    history.push(`${path}/data-solutions-invoicing`)

  return (
    <ActionCardsPage>
      <FeatureValidator
        requireAll
        feature={AuthorizedFeatures.billingThisMonthReadOnly}
      >
        <ActionCard
          buttonText='Go to Variable Billing Workflow'
          description='Verify project performance, investigate issues,
          and generate invoices in Netsuite for variable billed project
          opportunities for the last four savings months. View historical data for prior months'
          handleClick={navigateThisMonth}
          title='Variable Billing Monthly Workflow'
        />
      </FeatureValidator>
      {userHasPermission(GROUPS.ISR_PROCESSOR_TOOL) && (
        <ActionCard
          buttonText='go to isr file processor'
          description='View, upload, and update ISR files'
          handleClick={navigateISRProcessor}
          title='ISR File Processor'
        />
      )}
      {userHasPermission(GROUPS.FIXED_INVOICES) && (
        <ActionCard
          buttonText='View and generate Data Solutions invoices'
          description='View, upload, and generate invoices'
          handleClick={navigateDataSolutionsInvoicing}
          title='Data Solutions Invoice Generator'
        />
      )}
    </ActionCardsPage>
  )
}

export default BillingMainPage
