import styled from 'styled-components'
import Spinner from '../../../../components/Spinner'
import { colors } from '../../../../utils/themes'

const SpinnerWrapper = styled.div`
  height: 190px;
  background: ${colors.gray9};
  display: grid;
  place-items: center;
`
const Loader = styled.div`
  display: flex;
  align-items: center;
`

const LoadingText = styled.span`
  font-size: 16px;
  margin-left: 10px;
`

export default function TableLoader() {
  return (
    <SpinnerWrapper>
      <Loader>
        <Spinner size='micro' />
        <LoadingText>The table is loading...</LoadingText>
      </Loader>
    </SpinnerWrapper>
  )
}
