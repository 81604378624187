import { TSEnergyStarSiteMeter } from '../../ducks/energyStarIntegration/types'

export interface TSDropdownValues {
  name: string
  id: string | boolean | null
  resource?: string[]
}

export const DropdownValues: Record<string, TSDropdownValues[]> = {
  resource: [
    {
      name: 'Electricity',
      id: 'electricity',
    },
    {
      name: 'Water',
      id: 'water',
    },
    {
      name: 'Natural Gas',
      id: 'naturalGas',
    },
  ],
  dataSource: [
    {
      name: 'Redaptive Meter',
      id: 'redaptiveMeter',
      resource: ['electricity', 'naturalGas'],
    },
    {
      name: 'Non Redaptive Meter',
      id: 'nonRedaptiveMeter',
      resource: ['water'],
    },
  ],
  dataAggregation: [
    {
      name: 'Whole Site',
      id: 'wholeSite',
      resource: ['electricity', 'naturalGas'],
    },
    {
      name: 'Meter',
      id: 'meter',
      resource: ['water', 'electricity'],
    },
  ],
  positive: [
    {
      name: 'Positive',
      id: true,
    },
  ],
  selection: [
    {
      name: 'N/A',
      id: 'NA',
    },
  ],
  unit: [
    {
      name: 'KWH',
      id: 'kWh',
      resource: ['electricity'],
    },
    {
      name: 'Gallons',
      id: 'gallons',
      resource: ['water'],
    },
    {
      name: 'CCF',
      id: 'ccf',
      resource: ['naturalGas'],
    },
  ],
}

interface TSTableDataArr {
  title: string
  id: keyof TSEnergyStarSiteMeter
  dropdownValues?: TSDropdownValues[]
  isDependentOnResource?: boolean
  fieldIsSpecificTo?: 'edit' | 'read'
  type?: 'checkbox' | 'dropdown' | 'input' | 'actions' | 'monthSelector'
}

export const TableDataArr: TSTableDataArr[] = [
  {
    title: 'Enable',
    id: 'enabled',
    type: 'checkbox',
  },
  {
    title: 'Resource',
    id: 'resource',
    dropdownValues: DropdownValues.resource,
    type: 'dropdown',
  },
  {
    title: 'Data Source',
    id: 'dataSource',
    isDependentOnResource: true,
    dropdownValues: DropdownValues.dataSource,
    type: 'dropdown',
  },
  {
    title: 'Data Aggregation',
    id: 'dataAggregation',
    isDependentOnResource: true,
    dropdownValues: DropdownValues.dataAggregation,
    type: 'dropdown',
  },
  {
    title: 'Selection',
    id: 'selection',
    dropdownValues: DropdownValues.selection,
    type: 'dropdown',
  },
  {
    title: 'Positive or Negative',
    id: 'positive',
    dropdownValues: DropdownValues.positive,
    type: 'dropdown',
  },
  {
    title: 'Unit of Measure',
    id: 'unit',
    isDependentOnResource: true,
    dropdownValues: DropdownValues.unit,
    type: 'dropdown',
  },
  {
    title: 'Auto Create ESPM Meter',
    id: 'meterAutoCreation',
    fieldIsSpecificTo: 'edit',
    type: 'checkbox',
  },
  {
    title: 'ESPM Meter ID',
    id: 'energyStarId',
    type: 'input',
  },
  {
    title: 'ESPM Start Date',
    id: 'espmStartDate',
    type: 'monthSelector',
  },
  {
    title: '',
    fieldIsSpecificTo: 'edit',
    id: 'deleteColumn',
    type: 'actions',
  },
]
