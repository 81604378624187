import { breadcrumbs } from './constants'
import SiteReadiness from './tabs/SiteReadiness'
import Breadcrumbs from '../../../components/Breadcrumbs'
import TabPane from '../../../components/Tabs/TabPane'

const HydropointMeterTools = () => {
  const tabs = [
    {
      tab: 'site-readiness',
      text: 'Site Readiness',
      render: () => <SiteReadiness />,
    },
    {
      tab: 'meter-detail',
      text: 'Meter Detail',
      render: () => <div>Meter Detail</div>,
    },
  ]

  return (
    <div>
      <Breadcrumbs items={breadcrumbs} />
      <TabPane tabs={tabs} />
    </div>
  )
}

export default HydropointMeterTools
