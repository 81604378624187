import { useState } from 'react'

import { Input, InputWrapper, SearchClear } from './styles'

const VIEWS = {
  SEARCH: 'SEARCH',
  RESET: 'RESET',
}

type Props = {
  getInputValue: (mac: string) => void
  input: string
}

export default function SearchBar({ getInputValue, input }: Props) {
  const [searchText, setSearchText] = useState(input)
  const [view, setView] = useState(input ? VIEWS.RESET : VIEWS.SEARCH)

  const handleTextChange = (e) => {
    if (view === VIEWS.RESET) {
      setView(VIEWS.SEARCH)
    }
    setSearchText(e.target.value)
  }

  const onSearchOrClear = () => {
    if (view === VIEWS.SEARCH && searchText.length === 12) {
      setView(VIEWS.RESET)
      getInputValue(searchText)
    }
    if (view === VIEWS.RESET) {
      getInputValue('')
      setSearchText('')
      setView(VIEWS.SEARCH)
    }
  }

  return (
    <InputWrapper>
      <Input
        type='text'
        value={searchText}
        onChange={handleTextChange}
        maxLength={12}
        placeholder='Search Meter MAC'
      />

      <SearchClear onClick={onSearchOrClear}>
        {view === VIEWS.SEARCH ? 'Search' : 'Reset'}
      </SearchClear>
    </InputWrapper>
  )
}
