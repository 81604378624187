import axios from 'axios'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { consoleApiUrl, defaultHeaders, getEnergyUrl } from '../../../api'
import { handleAxiosError } from '../../../api/utils'

// Types
export type TSInvoiceDataItemType = {
  opportunityId: string
  savingsMonth: string
  invoiceNumber: string
  fixedBillingType: string
  status: string
  amount: number
  createdBy: string
  createdDate: string
}

export type TSInvoiceDataState = {
  dsiData: Array<TSInvoiceDataItemType>
  dsiDataMeta: { loading: boolean; error: string | null }
}
export type TSInvoiceDataStateRoot = {
  invoiceData: TSInvoiceDataState
}
type TSFetchInvoiceAction = {
  invoiceId: string
  savingsMonth?: string
  fixedBillingType?: string
}

// Action Types
export const types = {
  FETCH_INVOICE_DATA_REQUEST: 'FETCH_INVOICE_DATA_REQUEST',
  FETCH_INVOICE_DATA_SUCCESS: 'FETCH_INVOICE_DATA_SUCCESS',
  FETCH_INVOICE_DATA_FAILURE: 'FETCH_INVOICE_DATA_FAILURE',
  DOWNLOAD_INVOICE_PDF: 'DOWNLOAD_INVOICE_PDF',
  DOWNLOAD_INVOICE_SUCCESS: 'DOWNLOAD_INVOICE_PDF_SUCCESS',
  DOWNLOAD_INVOICE_ERROR: 'DOWNLOAD_INVOICE_PDF_ERROR',
}

type FetchInvoiceDataRequestAction = {
  type: typeof types.FETCH_INVOICE_DATA_REQUEST
}
type FetchInvoiceDataSuccessAction = {
  type: typeof types.FETCH_INVOICE_DATA_SUCCESS
  payload: Array<TSInvoiceDataItemType>
}
type FetchInvoiceDataFailureAction = {
  type: typeof types.FETCH_INVOICE_DATA_FAILURE
  payload: string
}

type InvoiceDataActionTypes =
  | FetchInvoiceDataRequestAction
  | FetchInvoiceDataSuccessAction
  | FetchInvoiceDataFailureAction

// Selectors
export const selectInvoiceData = (
  state: TSInvoiceDataStateRoot,
): TSInvoiceDataState => state.dsiTableData

// Initial State
export const initialState: TSInvoiceDataState = {
  dsiDataMeta: { loading: false, error: null },
  dsiData: [] as Array<TSInvoiceDataItemType>,
}

// Reducer
const invoiceDataReducer = (
  state: TSInvoiceDataState = initialState,
  action: InvoiceDataActionTypes,
): TSInvoiceDataState => {
  switch (action.type) {
    case types.FETCH_INVOICE_DATA_REQUEST:
      return { ...state, dsiDataMeta: { loading: true, error: null } }

    case types.FETCH_INVOICE_DATA_SUCCESS:
      return {
        ...state,
        dsiDataMeta: { loading: false, error: null },
        dsiData: action.payload,
      }

    case types.FETCH_INVOICE_DATA_FAILURE:
      return {
        ...state,
        dsiDataMeta: { loading: false, error: action.payload },
      }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  fetchInvoiceData: (params: TSFetchInvoiceAction) => ({
    type: types.FETCH_INVOICE_DATA_REQUEST,
    ...params,
  }),
  fetchInvoiceDataSuccess: (data: Array<TSInvoiceDataItemType>) => ({
    type: types.FETCH_INVOICE_DATA_SUCCESS,
    payload: data,
  }),
  fetchInvoiceDataFailure: (error: string) => ({
    type: types.FETCH_INVOICE_DATA_FAILURE,
    payload: error,
  }),
  downloadInvoiceById: (request: TSFetchInvoiceAction) => ({
    type: types.DOWNLOAD_INVOICE_PDF,
    request,
  }),
}

// API Calls
const API = {
  fetchInvoiceData: async (savingsMonth, fixedBillingType) => {
    try {
      const { data } = await axios.get(
        `${consoleApiUrl()}/billing/fixed/invoices?savingsMonth=${savingsMonth}&&fixedBillingType=${fixedBillingType}`,
        { headers: defaultHeaders() },
      )
      return data
    } catch (error) {
      throw handleAxiosError(error)
    }
  },
  downloadInvoiceById: async ({ invoiceId }: TSFetchInvoiceAction) => {
    const url = `${getEnergyUrl()}/api/invoices/${invoiceId}/invoice-pdf`
    return axios
      .get(url, {
        headers: { ...defaultHeaders(), 'Content-type': 'application/pdf' },
        responseType: 'blob',
      })
      .then(({ data }: { data: Blob; headers: any }) => {
        const url = URL.createObjectURL(data)
        window.open(url, '_blank')
      })
      .catch(handleAxiosError)
  },
}

// Sagas
function* fetchInvoiceDataSaga({
  type,
  savingsMonth,
  fixedBillingType,
}: {
  type: string
  savingsMonth: string
  fixedBillingType: string
}): Generator<any, void, any> {
  try {
    const data = yield call(
      API.fetchInvoiceData,
      savingsMonth,
      fixedBillingType,
    )
    yield put(actions.fetchInvoiceDataSuccess(data))
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      error.message ||
      'Failed to fetch invoice data'
    yield put(actions.fetchInvoiceDataFailure(errorMessage))
  }
}

function* downloadInvoiceByIdSaga({
  request,
}: {
  request: TSFetchInvoiceAction
  type: string
}): Generator<any, void, any> {
  try {
    const response: TSInvoiceSummariesBySiteResponse = yield call(
      API.downloadInvoiceById,
      request,
    )
    yield put({
      type: types.DOWNLOAD_INVOICE_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.DOWNLOAD_INVOICE_ERROR,
      payload: error.message || 'Failed to download invoice PDF',
    })
  }
}

export const sagas = [
  takeLatest(types.FETCH_INVOICE_DATA_REQUEST, fetchInvoiceDataSaga),
  takeEvery(types.DOWNLOAD_INVOICE_PDF, downloadInvoiceByIdSaga),
]

export default invoiceDataReducer
