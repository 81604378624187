import Tippy from '@tippyjs/react'
import { debounce } from 'debounce'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DatePicker from '../../../components/DatePicker'
import SearchBar from '../../../components/EntityList/SearchBar'
import ListSelector from '../../../components/ListSelector'
import Paginator from '../../../components/Paginator'
import RedaptiveReactTable7 from '../../../components/RedaptiveReactTable7'
import TableHead from '../../../components/RedaptiveReactTable7/TableHead'
import Spinner from '../../../components/Spinner'
import * as consts from '../../../constants'
import {
  actions as meterInstallActions,
  selectMeterGroupedSumissions,
} from '../../../ducks/meterInstallSubmissions/meterInstallGroupSubmissions'
import { FTSortable } from '../../../types'
import {
  baseOptions,
  format,
  getProgramStartDate,
} from '../../EvDashboard/constants'
import {
  defaultMeterInstallWidths,
  dropdownItems,
  submissionHistoryTableHeaders,
} from '../constants'
import { SpinnerStyles } from '../MeterTable/styles'
import {
  Cell,
  FilterContainerStyled,
  NoDataWrapper,
  PaginatorStyles,
} from '../styles'
import {
  CloseButtonStyled,
  DateLinkStyled,
  ModalBodyStyled,
  ModalContentStyled,
  ModalContentWrapperStyled,
  ModalHeaderStyled,
  ModalStyled,
  TableStyles,
  WrapperStyled,
} from './styles'
import { DatePickerContainerStyled } from '../../EvDashboard/styles'

export type FTModalMeterSubmissionHistoryProps = {
  meterId: string
  opportunityId: string
  locationAddress: string
  meterNavigationId: string
  customerName: string
  closeModal: (...args: Array<any>) => any
}

const MeterSubmissionHistoryModal = ({
  meterId,
  opportunityId,
  locationAddress,
  meterNavigationId,
  customerName,
  closeModal,
}: FTModalMeterSubmissionHistoryProps) => {
  const dispatch = useDispatch()
  const {
    metersByOpportunity: { data, meta, searchParams },
  } = useSelector((state) => selectMeterGroupedSumissions(state))
  const results = [...data]
  const { loading, error, totalCount, totalPages, next, previous } = meta
  const {
    from,
    to,
    macAddress,
    isAssignedToSite,
    createdBy,
    evVerified,
    preConfigTestStatus,
    postConfigTestStatus,
    startConfigAcceptedDate,
    endConfigAcceptedDate,
    pageSize,
    pageNumber,
    panelNames,
    orderBy,
  } = searchParams

  const [orderParams, setOrderParams] = useState<FTSortable>({
    field: 'modified',
    sort: 'DESC',
  })

  const [submittedDate, setSubmittedDate] = useState('PROGRAM_TO_DATE')
  const [submittedDateRange, setSubmittedDateRange] = useState({
    from: moment('2022-01-01').format(consts.DATE_FORMAT_DATA_API_REQUEST),
    to: moment().format(consts.DATE_FORMAT_DATA_API_REQUEST),
  })

  const [configAcceptedDate, setConfigAcceptedDate] =
    useState('PROGRAM_TO_DATE')
  const [configAcceptedDateRange, setConfigAcceptedDateRange] = useState({
    startConfigAcceptedDate: moment('2022-01-01').format(
      consts.DATE_FORMAT_DATA_API_REQUEST,
    ),
    endConfigAcceptedDate: moment().format(consts.DATE_FORMAT_DATA_API_REQUEST),
  })

  const handleSelector = (field, value) => {
    dispatch(
      meterInstallActions.fetchMetersForOppurtunity({
        ...searchParams,
        orderBy: orderParams,
        macAddress: meterId,
        opportunityId,
        [field]: value,
        pageNumber: 1,
      }),
    )
  }

  const renderSubmissionDateLabel = useCallback(() => {
    const { start, end } = {
      start: submittedDateRange.from,
      end: submittedDateRange.to,
    }
    const startDate = moment(start, consts.URL_DATE_FORMAT)
    const startText = startDate.format(format)
    let text = startText
    const endText =
      end ? moment(end, consts.URL_DATE_FORMAT).format(format) : ''

    if (end && endText !== startText) {
      text = `${text} - ${endText}`
    }

    return text
  }, [submittedDateRange])

  const handleUpdateSubmissionDateRange = useCallback(
    ({ endDate, startDate, selectedOptionDate }) => {
      if (selectedOptionDate) {
        setSubmittedDate(selectedOptionDate)
      } else {
        setSubmittedDate('')
      }
      const updatedSearchParams = {
        from: moment(startDate)
          .utc()
          .startOf('day')
          .format(`${consts.DATE_FORMAT_DATA_API_REQUEST}Z`),
        to: moment(endDate)
          .utc()
          .endOf('day')
          .format(`${consts.DATE_FORMAT_DATA_API_REQUEST}Z`),
      }
      setSubmittedDateRange({ ...updatedSearchParams })
      dispatch(
        meterInstallActions.fetchMetersForOppurtunity({
          ...searchParams,
          macAddress: meterId,
          opportunityId,
          orderBy: orderParams,
          ...updatedSearchParams,
          pageNumber: 1,
        }),
      )
    },
    [dispatch, meterId, opportunityId, orderParams, searchParams],
  )

  const renderConfigAcceptedDateLabel = useCallback(() => {
    const { start, end } = {
      start: configAcceptedDateRange.startConfigAcceptedDate,
      end: configAcceptedDateRange.endConfigAcceptedDate,
    }
    const startDate = moment(start, consts.URL_DATE_FORMAT)
    const startText = startDate.format(format)
    let text = startText
    const endText =
      end ? moment(end, consts.URL_DATE_FORMAT).format(format) : ''

    if (end && endText !== startText) {
      text = `${text} - ${endText}`
    }

    return text
  }, [configAcceptedDateRange])

  const handleUpdateConfigAcceptedDateRange = useCallback(
    ({ endDate, startDate, selectedOptionDate }) => {
      if (selectedOptionDate) {
        setConfigAcceptedDate(selectedOptionDate)
      } else {
        setConfigAcceptedDate('')
      }
      const updatedSearchParams = {
        startConfigAcceptedDate: moment(startDate)
          .utc()
          .startOf('day')
          .format(`${consts.DATE_FORMAT_DATA_API_REQUEST}Z`),
        endConfigAcceptedDate: moment(endDate)
          .utc()
          .endOf('day')
          .format(`${consts.DATE_FORMAT_DATA_API_REQUEST}Z`),
      }
      setConfigAcceptedDateRange({ ...updatedSearchParams })
      dispatch(
        meterInstallActions.fetchMetersForOppurtunity({
          ...searchParams,
          macAddress: meterId,
          opportunityId,
          orderBy: orderParams,
          ...updatedSearchParams,
          pageNumber: 1,
        }),
      )
    },
    [dispatch, meterId, opportunityId, orderParams, searchParams],
  )

  const search = (searchBy: { field: string; term: string }) => {
    dispatch(
      meterInstallActions.fetchMetersForOppurtunity({
        ...searchParams,
        macAddress: meterId,
        opportunityId,
        [searchBy.field]: searchBy.term,
        orderBy: orderParams,
        pageNumber: 1,
      }),
    )
  }

  const handleSearch = debounce(search, 500)

  const selectFilter = (id): JSX.Element | null => {
    let toreturn: JSX.Element | null = null
    if (
      id === 'preConfigTestStatus' ||
      id === 'postConfigTestStatus' ||
      id === 'evVerified' ||
      id === 'isDeleted'
    ) {
      toreturn = (
        <FilterContainerStyled width={defaultMeterInstallWidths[id].minWidth}>
          <ListSelector
            items={dropdownItems[id]}
            updateValue={({ value }) => handleSelector(id, value)}
            selectedItem={{
              id: searchParams[id],
              name: searchParams?.[id],
            }}
            unsettable={false}
          />
        </FilterContainerStyled>
      )
    }
    if (id === 'modified') {
      toreturn = (
        <FilterContainerStyled width={defaultMeterInstallWidths[id].minWidth}>
          <DatePickerContainerStyled key={id}>
            <DatePicker
              end={moment(submittedDateRange.to)}
              options={baseOptions}
              period={submittedDate}
              getProgramStartDate={getProgramStartDate}
              start={moment(submittedDateRange.from)}
              text={renderSubmissionDateLabel()}
              updateDateRange={handleUpdateSubmissionDateRange}
            />
          </DatePickerContainerStyled>
        </FilterContainerStyled>
      )
    }
    if (id === 'configAcceptedDate') {
      toreturn = (
        <FilterContainerStyled width={defaultMeterInstallWidths[id].minWidth}>
          <DatePickerContainerStyled key={id}>
            <DatePicker
              end={moment(configAcceptedDateRange.startConfigAcceptedDate)}
              options={baseOptions}
              period={configAcceptedDate}
              getProgramStartDate={getProgramStartDate}
              start={moment(configAcceptedDateRange.endConfigAcceptedDate)}
              text={renderConfigAcceptedDateLabel()}
              updateDateRange={handleUpdateConfigAcceptedDateRange}
            />
          </DatePickerContainerStyled>
        </FilterContainerStyled>
      )
    }

    if (id === 'panelNames' || id === 'createdBy') {
      toreturn = (
        <FilterContainerStyled
          width={defaultMeterInstallWidths[id]?.minWidth}
          key={id}
        >
          <SearchBar
            onSearch={handleSearch}
            filterField={id}
            previous={{
              field: id,
              term: searchParams[id] ? searchParams[id] : '',
            }}
          />
        </FilterContainerStyled>
      )
    }
    return toreturn
  }
  const handleSortClick = ({
    currentTarget: {
      dataset: { id },
    },
  }) => {
    if (orderParams.field) {
      if (orderParams.field !== id || orderParams.sort === 'DESC') {
        setOrderParams({
          field: id,
          sort: 'ASC',
        })
      } else {
        setOrderParams({
          field: id,
          sort: 'DESC',
        })
      }
    }
  }

  const browseToPage = (number) => {
    dispatch(
      meterInstallActions.fetchMetersForOppurtunity({
        ...searchParams,
        macAddress: meterId,
        opportunityId,
        orderBy: orderParams,
        pageSize,
        pageNumber: Number(number),
      }),
    )
  }

  const tableHeaderColumns = () => {
    const headerCols = submissionHistoryTableHeaders.map((col) => ({
      id: col.id,
      label: col.label,
      sortable: col.sorting,
      sorted: orderParams?.field === col.id,
      sortDesc: orderParams?.sort === 'DESC',
      minWidth: defaultMeterInstallWidths[col.id]?.minWidth,
      maxWidth: defaultMeterInstallWidths[col.id]?.maxWidth,
      handleSortClick: col.sorting ? handleSortClick : () => {},
      Filter: () => (col.filtering ? selectFilter(col.id) : () => {}),
    }))
    return [
      {
        id: 'row3',
        headers: headerCols,
      },
    ]
  }

  const TableHeadComponent = () => <TableHead rows={tableHeaderColumns()} />

  const getColumnCell = useCallback((cellProps) => {
    const {
      row: {
        original: { id: submissionId },
      },
      column: { id },
      value,
    } = cellProps

    switch (id) {
      case 'modified':
        return (
          <DateLinkStyled
            href={`/reports/meter-install-submissions/${submissionId}`}
          >
            {moment(value).format('MM/DD/YYYY, hh:mm:ss A')} UTC
          </DateLinkStyled>
        )
      case 'configAcceptedDate':
        return `${moment(value).format('MM/DD/YYYY, hh:mm:ss A')} UTC`
      case 'isDeleted':
        return value ? 'YES' : 'NO'
      default:
        return (
          <Tippy content={value}>
            <Cell maxWidth={defaultMeterInstallWidths[id]?.maxWidth}>
              {value}
            </Cell>
          </Tippy>
        )
    }
  }, [])

  const columns = useMemo(
    () =>
      submissionHistoryTableHeaders.map((col) => ({
        accessor: col.id,
        Cell: getColumnCell,
        Header: col.label,
        minWidth: defaultMeterInstallWidths[col.id]?.minWidth,
        maxWidth: defaultMeterInstallWidths[col.id]?.maxWidth,
      })),
    [getColumnCell],
  )

  useEffect(() => {
    if (
      !results.length ||
      JSON.stringify(orderBy) !== JSON.stringify(orderParams)
    ) {
      dispatch(
        meterInstallActions.fetchMetersForOppurtunity({
          ...searchParams,
          macAddress: meterId,
          orderBy: orderParams,
          opportunityId,
        }),
      )
    }
  }, [dispatch, meterId, opportunityId, orderParams])

  const handleCloseModal = () => {
    dispatch(meterInstallActions.resetMeterDataForOpportunity())
    closeModal()
  }
  return (
    <ModalStyled>
      <ModalContentWrapperStyled>
        <ModalContentStyled>
          <ModalHeaderStyled>
            <div>
              <h3>Submission History</h3>
              <a href={`/account-management/meters/${meterNavigationId}`}>
                {meterId}
              </a>
              <p>
                <b>
                  {opportunityId} | {locationAddress} | {customerName}
                </b>
              </p>
            </div>
            <CloseButtonStyled
              onClick={handleCloseModal}
              className='ion-android-close'
            />
          </ModalHeaderStyled>
          <ModalBodyStyled>
            <WrapperStyled>
              <TableStyles>
                <RedaptiveReactTable7
                  columns={columns}
                  data={results}
                  globalFilterable={false}
                  TableHead={TableHeadComponent}
                  showTableScrollArrows={false}
                />
                {!loading && results.length === 0 && (
                  <NoDataWrapper>No Data Available</NoDataWrapper>
                )}
                {loading ?
                  <SpinnerStyles>
                    <Spinner />
                  </SpinnerStyles>
                : <PaginatorStyles>
                    <Paginator
                      total={totalPages}
                      current={pageNumber || 1}
                      next={next}
                      prev={previous}
                      browseToPage={browseToPage}
                      showArrows
                    />
                  </PaginatorStyles>
                }
              </TableStyles>
            </WrapperStyled>
          </ModalBodyStyled>
        </ModalContentStyled>
      </ModalContentWrapperStyled>
    </ModalStyled>
  )
}

export default MeterSubmissionHistoryModal
