import Tippy from '@tippyjs/react'
import RedaptiveReactTable7 from '../../../../components/RedaptiveReactTable7'
import { useSiteReadiness } from '../../../../queries/hydropoint'
import {
  fieldMappings,
  initialHiddenColumns,
  initialLockedColumns,
  alwaysLockedColumns,
  handleColumnSettingsClose,
} from './columnSettings'
import { TableWrapperStyled, Title } from '../styles'
import { Cell } from '../../../MeterInstallGroupedSubmissions/styles'
import { paginationSize } from '../constants'
import Loader from '../components/Loader'

const SiteReadiness = () => {
  const { data, isLoading } = useSiteReadiness()

  const getColumnCell = (cellProps) => {
    const { value } = cellProps
    return (
      (
        <Tippy content={value}>
          <Cell maxWidth={200}>{value}</Cell>
        </Tippy>
      ) || '-'
    )
  }

  const columns = fieldMappings.map((item) => ({
    accessor: item.fieldName,
    Cell: getColumnCell,
    Header: item.label,
    minWidth: item.minWidth || 150,
    fieldName: item.fieldName,
  }))

  return (
    <>
      <div>
        <p>
          Use this tool to find calibrated meters that are ready to be assigned
          to a Grand Central site. This tool only returns un-assigned meters
          that meet the following readiness criteria:
        </p>
        <ul>
          <li>Must be Level 2 calibrated</li>
          <li>Must have a calibrated start date</li>
          <li>Must have a last calibration date</li>
        </ul>
        <p>
          If you’re looking for information about meters regardless of
          calibration level, use the Meter Details Tool below.
        </p>
      </div>
      <TableWrapperStyled>
        <Title>
          <div>Site Readiness</div>
        </Title>
        <RedaptiveReactTable7
          columns={columns}
          data={data || []}
          globalFilterable={false}
          filterable
          disableSortRemove
          maxLockableColumns={3}
          enableColumnHiding
          enableColumnSettings
          initialHiddenColumns={initialHiddenColumns}
          initialLockedColumns={initialLockedColumns}
          alwaysLockedColumns={alwaysLockedColumns}
          handleColumnSettingsClose={handleColumnSettingsClose}
          initialPageSize={20}
          paginationSize={paginationSize}
          enablePagination
          enablePageSizeSelector
          hideDropdownPagination
          hideSeparator
          loading={isLoading}
          noDataFoundText='No data found. Change the filters and try again.'
        />
      </TableWrapperStyled>
      {isLoading && <Loader />}
    </>
  )
}

export default SiteReadiness
