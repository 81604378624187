import styled from 'styled-components'

import { GlobalFilterInput } from '../../../../components/RedaptiveReactTable7'
import { zIndices } from '../../../../utils'

export const SpinnerStyles = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

export const GroupJustifiedEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
export const GroupJustifiedBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
`
export const Text = styled.p`
  font-size: 16px;
  font-weight: 500;
`

export const Paper = styled.div`
  background-color: white;
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  ${GlobalFilterInput} {
    height: 16px;
  }
`

export const ActionsCellWrapperStyled = styled.div`
  position: relative;
`
export const ActionIconStyled = styled.div`
  cursor: pointer;
  width: 28px;
  height: 36px;
  > span {
    position: static;
  }
`
export const ActionsPopupStyled = styled.div<{
  open: boolean
}>`
  background: #fff;
  box-shadow: 0 0 10px 2px rgba(130, 130, 130, 0.25);
  border-radius: 4px;
  display: ${({ open }) => (open ? 'block' : 'none')};
  left: -220px;
  padding: 16px;
  position: absolute;
  top: 40px;
  width: 214px;
  z-index: ${zIndices.RedaptiveReactTableActionsPopup};
`
export const ActionStyled = styled.div<{ color?: string }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 16px;
  color: ${({ color }) => color ?? '#464a54'};

  &:last-child {
    margin-bottom: 0;
  }
`
export const ActionIconWrapperStyled = styled.div`
  align-items: center;
  display: flex;
  margin-right: 5px;
`

export const CloseDivStyled = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(74, 74, 74, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

export const ModalContainer = styled.div`
  background: rgb(246, 246, 246);
  border-radius: 8px;
  padding: 32px;
  width: 500px;
  max-width: 100%;
  position: relative;
`

export const ModalHeader = styled.div`
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 24px;
`

export const TextInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
`

export const InProgressIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 26px;
  span {
    font-weight: 400;
    color: #162447;
  }
`
