// eslint-disable-next-line import/prefer-default-export
export const ADD_SCENARIO_STATES = {
  DANGER: 'danger',
  WARNING: 'warning',
  SUCCESS: 'success',
  LOADING: 'loading',
  FILE_NAME_LENGTH: 'fileNameLength',
  FIELD_MAPPING_ERROR: 'fieldMappingError',
}

export const UPDATE_SALESFORCE_STATES = {
  SUCCESS: 'success',
  LOADING: 'loading',
}

export const scenarioFieldsPrecision = {
  materialCosts: 2,
  laborCosts: 2,
  otherCost: 2,
  vendorSalesTax: 2,
  utilityRate: 4,
  metricTonsOfCo2AvoidedOverEul: 0,
  metricTonsOfCo2AvoidedAnnually: 0,
  metricTonsOfCo2AvoidedContractTerm: 0,
  savedDollarsOnAvoidedRecPurchase: 2,
  lightingRelampBypassBallastCount: 0,
  lightingReplaceFixtureCount: 0,
  lightingRetrofitKitCount: 0,
  lightingRemoveFixtures: 0,
  lightingSensorsOnly: 0,
  lightingNoChange: 0,
  lightingRelampCount: 0,
  preKw: 2,
  annualPreKwh: 4,
  postKw: 2,
  annualPostKwh: 2,
  maintenanceSavingsPerFixture: 2,
}

export const ACCEPTED_IMAGE_EXTENSIONS = [
  'jpeg',
  'jpg',
  'png',
  'webp',
  'jp2',
  'tiff',
  'avif',
  'heif',
  'raw',
]
export const API_SOURCE = {
  scenario: 'scenario',
}

export enum GROUPS {
  ENERGY_STAR_ADMIN = 'energy-star-admin',
  ISR_PROCESSOR_TOOL = 'isr-processor-tool',
  ACCOUNT_MANAGER = 'account-managers',
  OPERATIONS_SUPPORT = 'operations-support',
  PROPOSAL_OPERATIONS = 'proposal-operations',
  PROPOSAL_OPERATIONS_ADMIN = 'proposal-operations-admin',
  PROPOSAL_OPERATIONS_READONLY = 'proposal-operations-readonly',
  ADMIN = 'admin',
  HVAC_ASSETS_UPLOADER = 'hvac-assets-uploader',
  LTG_AS_BUILT_UPLOADER = 'lgt-as-built-uploader',
  BILLING = 'billing',
  BILLING_READ_ONLY = 'billing-read-only',
  BILLING_ISSUE_INVESTIGATOR = 'billing-issue-investigator',
  BILLING_PERFORMANCE_REVIEWER = 'billing-performance-reviewer',
  BILLING_INVOICE_APPROVER = 'billing-invoice-approver',
  MANUFACTURING = 'manufacturing',
  USER = 'user',
  FIXED_INVOICES = 'fixed-invoices',
}

export const currentYear = new Date().getFullYear()
