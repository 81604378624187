import moment, { Moment } from 'moment'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { selectSiteEntity } from '../../ducks/sites'
import MonthPicker, { MonthSelectorButton } from '../MonthPicker'

const Wrapper = styled.div`
  ${MonthSelectorButton} {
    padding: 0 5px;
    width: 130px;
    min-width: 130px;
  }
`

type RenderMonthPickerProps = {
  onSubmit?: (month: string) => void
  selectedMonth?: string
  resourceType: string
}

const resourceTypeToSiteParam = {
  naturalGas: 'ingestionDataStartNaturalGas',
  electricity: 'ingestionDataStartElectricity',
  water: 'ingestionDataStartWater',
}

const RenderMonthPicker = ({
  onSubmit,
  selectedMonth,
  resourceType,
}: RenderMonthPickerProps) => {
  const params = useParams<{ siteId: string }>()

  const site = useSelector((state: any) =>
    selectSiteEntity(state, params.siteId),
  )?.item

  const startDate = site?.[resourceTypeToSiteParam[resourceType]]?.split('T')[0]
  const firstOfMonthStartDate =
    moment(startDate, 'YYYY-MM-DD').date() === 1 ?
      moment(startDate, 'YYYY-MM-DD')
    : moment(startDate, 'YYYY-MM-DD').add(1, 'month').date(1)

  const [showMonthPickerDropDown, setShowMonthPickerDropDown] = useState(false)

  const [selectedYearMonth, setSelectedYearMonth] = useState(
    moment(selectedMonth?.split(' ')[0], 'YYYY-MM-DD'),
  )

  const handleOnSubmit = (month: Moment) => {
    if (onSubmit) onSubmit(month.format('YYYY-MM-DD 00:00:00.0'))

    setSelectedYearMonth(month)
    setShowMonthPickerDropDown(false)
  }

  return (
    <Wrapper>
      <MonthPicker
        position='left'
        startMonth={firstOfMonthStartDate}
        activeMonth={selectedYearMonth}
        showMonthPickerDropDown={showMonthPickerDropDown}
        setShowMonthPickerDropDown={setShowMonthPickerDropDown}
        onCancel={() => {
          setShowMonthPickerDropDown(false)
        }}
        onSubmit={handleOnSubmit}
      />
    </Wrapper>
  )
}

export default RenderMonthPicker
