import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import user from './auth'
import entities from './entities'
import requestStatus from './requestStatus'
import { LOGIN_FAILURE } from '../constants/actionTypes'
import auditVendor from '../ducks/auditVendorValidations'
import auth from '../ducks/auth'
import authzPlayground from '../ducks/authzPlayground'
import dataSolutionInvoicing from '../ducks/billing/DataSolutionsInvoicing/dataSolutionInvoicing'
import dsiTableData from '../ducks/billing/DataSolutionsInvoicing/dsiTableItems'
import isrProcessing from '../ducks/billing/ISR/isrProcessingTool'
import isrData from '../ducks/billing/ISR/isrTableItems'
import meterConfigHistory from '../ducks/configHistory'
import energyStarIntegration from '../ducks/energyStarIntegration'
import hvacAssetReducer from '../ducks/hvacAsset'
import lightingAsBuiltsAssetReducer from '../ducks/lightingAsBuiltsAsset'
import lightingControlsTypeGuideAssetReducer from '../ducks/lightingControlsTypeGuideAsset'
import measureCodeGuideAssetReducer from '../ducks/measureCodeGuideAsset'
import messageFlasher from '../ducks/messageFlasher'
import messages from '../ducks/messages'
import meterExport from '../ducks/meterExport'
import meterInstall from '../ducks/meterInstallReport'
import meterConfig from '../ducks/meters/config'
import metersMostRecentMeasurementData from '../ducks/metersMostRecentMeasurementData'
import meterStatus from '../ducks/meterStatus'
import modal from '../ducks/modal'
import proposalOpportunitySummaryDownload from '../ducks/proposals/opportunitySummaryDownload'
import upload from '../ducks/upload'
import uploadUtilityData from '../ducks/utilityData'

const rootReducer =
  (history: History) =>
  (
    state: Record<string, any>,
    action: {
      type: string
    },
  ) => {
    const logoutState = {
      user: {
        notice: 'logout',
      },
    }
    const { user: userLogoutState } = logoutState
    const { user: userState = {} } = state
    const { loginFormEmailFieldValue = '' } = userState
    const noAccessState = {
      user: { ...userLogoutState, loginFormEmailFieldValue },
    }
    let newState

    if (action.type === 'LOGOUT_REQUEST') {
      newState = logoutState
    } else if (action.type === LOGIN_FAILURE) {
      newState = noAccessState
    } else {
      newState = state
    }

    return combineReducers({
      auth,
      authzPlayground,
      entities,
      hvacAsset: hvacAssetReducer,
      lightingAsBuiltsAsset: lightingAsBuiltsAssetReducer,
      lightingControlsTypeGuideAsset: lightingControlsTypeGuideAssetReducer,
      measureCodeGuideAsset: measureCodeGuideAssetReducer,
      messageFlasher,
      messages,
      meterConfig,
      meterExport,
      meterInstall,
      energyStarIntegration,
      metersMostRecentMeasurementData,
      meterStatus,
      modal,
      proposalOpportunitySummaryDownload,
      requestStatus,
      router: connectRouter(history),
      upload,
      uploadUtilityData,
      user,
      meterConfigHistory,
      auditVendor,
      isrProcessing,
      dataSolutionInvoicing,
      isrData,
      dsiTableData,
    })(newState, action)
  }

export default rootReducer
