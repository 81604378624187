import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import DataSolutionInvoicingTable from './DataSolutionInvoicingUploadDataTable'
import FileUploadModal from './FileUploadModal'
import Button from '../../../components/Button2'
import UploadIcon from '../../../components/Icons/svg/UploadIcon'
import MonthPicker from '../../../components/MonthPicker'
import PageHeader from '../../../components/PageHeader'
import { actions as dsiActions } from '../../../ducks/billing/DataSolutionsInvoicing/dsiTableItems'
import { FTWithRouter } from '../../../types'
import {
  DataSolutionInvoicingPageStyled,
  FileUploadSectionContainer,
  HeaderStyled,
  IconContainer,
  MonthPickerWrapperStyles,
} from '../BillingThisMonth/styles'

type FTProps = FTWithRouter
const DataSolutionInvoicingIndex = (props: FTProps) => {
  const { history } = props
  const [showFileUploadModal, setShowFileUploadModal] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState(moment())
  const [showMonthPickerDropDown, setShowMonthPickerDropDown] =
    React.useState(false)

  const handleBackNavigation = useCallback(() => {
    history.push('/billing')
  }, [history])
  const dispatch = useDispatch()
  const hideMonthPickerDropDown = () => {
    setShowMonthPickerDropDown(false)
  }
  const onCancelMonthSelector = () => {
    hideMonthPickerDropDown()
  }

  const onSubmitMonthSelector = (month) => {
    hideMonthPickerDropDown()
    setSelectedMonth(month)
  }

  useEffect(() => {
    dispatch(
      dsiActions.fetchInvoiceData({
        savingsMonth: selectedMonth.format('YYYY-MM'),
        fixedBillingType: 'DATA_SOLUTIONS',
      }),
    )
  }, [dispatch, selectedMonth])
  return (
    <DataSolutionInvoicingPageStyled>
      <HeaderStyled>
        <PageHeader
          Title='Data Solutions Invoice Generator'
          handleBackNavigation={handleBackNavigation}
        />
      </HeaderStyled>
      <FileUploadSectionContainer>
        <MonthPickerWrapperStyles>
          <MonthPicker
            activeMonth={selectedMonth}
            showMonthPickerDropDown={showMonthPickerDropDown}
            setShowMonthPickerDropDown={setShowMonthPickerDropDown}
            onCancel={onCancelMonthSelector}
            onSubmit={onSubmitMonthSelector}
            useCurrentMonth
          />
        </MonthPickerWrapperStyles>
        <Button
          type='outlined'
          onClick={() => {
            setShowFileUploadModal(true)
          }}
          disabled={false}
        >
          <IconContainer>
            <UploadIcon height={16} width={16} />
          </IconContainer>
          &nbsp; Upload Invoice File
        </Button>
      </FileUploadSectionContainer>
      <DataSolutionInvoicingTable />
      <FileUploadModal
        showDilog={showFileUploadModal}
        setShowDialog={setShowFileUploadModal}
      />
    </DataSolutionInvoicingPageStyled>
  )
}
export default DataSolutionInvoicingIndex
