import Tippy from '@tippyjs/react'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import styled from 'styled-components'

import {
  Link,
  Loader,
  LoadingText,
  SpinnerStyles,
  TableStyles,
  ViewHistory,
  Wrapper,
} from './styles'
import RedaptiveReactTable7 from '../../../components/RedaptiveReactTable7'
import TableHead from '../../../components/RedaptiveReactTable7/TableHead'
import Spinner from '../../../components/Spinner'
import { Cell } from '../styles'
import { defaultMeterInstallWidths, meterTableHeaders } from './constants'
import {
  actions as meterInstallActions,
  selectMeterGroupedSumissions,
} from '../../../ducks/meterInstallSubmissions/meterInstallGroupSubmissions'
import { actions as modalActions } from '../../../ducks/modal'
import { FTSortable } from '../../../types'

const ModalStyles = styled.div`
  width: 1612px;
  height: 550px;

  // Center the modal
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 20px;
  z-index: 1050;
`

export default function MeterTable({ opportunityId }) {
  const match = useRouteMatch()

  const dispatch = useDispatch()
  const { url } = match
  const {
    meters: { data: meterData },
    meta,
  } = useSelector((state) => selectMeterGroupedSumissions(state))

  const { results = [], loading } = meterData?.[opportunityId] || {}
  const { searchParams: { macAddress = '' } = {} } = meta || {}
  const [orderParams, setOrderParams] = useState<FTSortable>({
    field: 'modified',
    sort: 'DESC',
  })
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false)
  const [historyModalProps, setHistoryModalProps] = useState({
    meterId: '',
    opportunityId: '',
    locationAddress: '',
    meterNavigationId: '',
    customerName: '',
  })

  useEffect(() => {
    dispatch(
      meterInstallActions.fetchMeterData({
        opportunityId,
        orderBy: orderParams,
        pageSize: 1000,
        macAddress,
      }),
    )
    return () => {
      dispatch(meterInstallActions.resetMeterData({ opportunityId }))
    }
  }, [opportunityId, orderParams])

  useEffect(() => {
    if (
      historyModalProps.meterId &&
      historyModalProps.opportunityId &&
      historyModalProps.locationAddress
    ) {
      if (!isHistoryModalOpen) {
        dispatch(
          modalActions.showModalMeterSubmissionHistory({
            ...historyModalProps,
            closeModal: () => {
              dispatch(modalActions.hideModal())
            },
          }),
        )
      }
    }
  }, [historyModalProps])

  const handleSortClick = useCallback(
    ({
      currentTarget: {
        dataset: { id },
      },
    }) => {
      if (orderParams?.field) {
        if (orderParams.field !== id || orderParams.sort === 'DESC') {
          setOrderParams({
            field: id,
            sort: 'ASC',
          })
        } else {
          setOrderParams({
            field: id,
            sort: 'DESC',
          })
        }
      }
    },
    [orderParams],
  )

  const tableHeaderRows = useMemo(() => {
    const showSorting = (item) =>
      item.id === 'macAddress' ||
      item.id === 'modified' ||
      item.id === 'createdBy'
    const headerRows = meterTableHeaders.map((item) => ({
      id: item.id,
      label: item.label,
      handleSortClick: showSorting(item) ? handleSortClick : () => {},
      sortable: showSorting(item),
      sorted: orderParams?.field === item.id,
      sortDesc: orderParams?.sort === 'DESC',
      minWidth: defaultMeterInstallWidths[item.id]?.minWidth,
      maxWidth: defaultMeterInstallWidths[item.id]?.maxWidth,
    }))
    return [
      {
        id: 'row2',
        headers: headerRows,
      },
    ]
  }, [orderParams, handleSortClick])

  const TableHeadComponent = () => <TableHead rows={tableHeaderRows} />

  const getColumnCell = useCallback(
    (cellProps) => {
      const {
        row: {
          original: {
            id: submissionId,
            meterId,
            macAddress,
            siteLocation,
            customerName,
          },
        },
        column: { id },
        value,
      } = cellProps
      const handleHistoryClick = () => {
        setHistoryModalProps({
          meterId: macAddress,
          opportunityId,
          locationAddress: siteLocation,
          meterNavigationId: meterId,
          customerName,
        })
      }

      switch (id) {
        case 'isDeleted':
          return value ? 'Yes' : 'No'
        case 'macAddress':
          return (
            <Link href={`/account-management/meters/${meterId}`}>{value}</Link>
          )
        case 'history':
          return (
            <ViewHistory onClick={handleHistoryClick}>View History</ViewHistory>
          )
        case 'configAcceptedDate':
          return `${moment(value).format('MM/DD/YYYY, hh:mm:ss A')} UTC`
        case 'modified':
          return (
            <Link href={`/reports/meter-install-submissions/${submissionId}`}>
              {moment(value).format('MM/DD/YYYY, hh:mm:ss A')} UTC
            </Link>
          )
        default:
          return (
            (
              <Tippy content={value}>
                <Cell maxWidth={defaultMeterInstallWidths[id]?.maxWidth}>
                  {value}
                </Cell>
              </Tippy>
            ) || '-'
          )
      }
    },
    [dispatch],
  )

  const columns = useMemo(
    () =>
      meterTableHeaders.map((item) => ({
        accessor: item.id,
        Cell: getColumnCell,
        Header: item.label,
        minWidth: defaultMeterInstallWidths[item.id]?.minWidth,
        maxWidth: defaultMeterInstallWidths[item.id]?.maxWidth,
      })),
    [getColumnCell],
  )
  if (loading) {
    return (
      <Wrapper>
        <SpinnerStyles>
          <Loader>
            <Spinner size='small' />
            <LoadingText>Loading...</LoadingText>
          </Loader>
        </SpinnerStyles>
      </Wrapper>
    )
  }
  if (results.length) {
    return (
      <tr>
        <Wrapper>
          <TableStyles>
            <RedaptiveReactTable7
              columns={columns}
              data={macAddress ? [results[0]] : results}
              globalFilterable={false}
              TableHead={TableHeadComponent}
              showTableScrollArrows={false}
            />
          </TableStyles>
        </Wrapper>
      </tr>
    )
  }
  return null
}
