import React from 'react'
import { useSelector } from 'react-redux'

import OrganizationGraph from '../../components/HighCharts/OrganizationGraph'
import { selectPanelListEntity } from '../../ducks/panels'
import { FTAllPanelEntities } from '../../ducks/types'
import { colors } from '../../utils/themes'

type PanelData = {
  from: string
  to: string
}

export const mapPanelToData = (
  panelListEntity: FTAllPanelEntities['allItems'],
): PanelData[] => {
  const panelData: PanelData[] = []

  panelListEntity.forEach((panel) => {
    panelData.push({
      from: panel.parentPanelId ? panel.parentPanelName : panel.name,
      to: panel.name,
    })
  })
  return panelData
}

const isParentNode = (panelListEntity, currPanelName) =>
  panelListEntity.some((panel) => panel.parentPanelName === currPanelName)

export const mapPanelToChartNode = (
  panelListEntity: FTAllPanelEntities['allItems'],
) =>
  panelListEntity.map((panel) => ({
    id: panel.name,
    color:
      isParentNode(panelListEntity, panel.name) || !!panel.parentPanelId ?
        colors.blue7
      : colors.red2,
    parent: panel.parentPanelId || null,
    name: panel.name,
    panelLevel: panel.panelLevel,
    parentPanelName: panel.parentPanelName,
  }))

export const mapPanelToLevels = (
  panelListEntity: FTAllPanelEntities['allItems'],
) => {
  const uniqueLevels = [
    ...new Set(panelListEntity.map((panel) => panel.panelLevel)),
  ]
  const panelLevels = uniqueLevels.map((_, index) => ({
    level: index,
  }))
  return panelLevels
}

const PanelHierarchyChart = () => {
  const panelList = useSelector(selectPanelListEntity)
  const panelData = mapPanelToData(panelList.allItems)
  const panelLevels = mapPanelToLevels(panelList.allItems)
  const panelNodes = mapPanelToChartNode(panelList.allItems)

  return (
    <OrganizationGraph
      data={panelData}
      levels={panelLevels}
      nodes={panelNodes}
      colorByPoint
      title=''
      exportFilename='Panel Hierarchy'
      exportOptions={[
        'viewFullscreen',
        'downloadPNG',
        'downloadJPEG',
        'downloadPDF',
      ]}
    />
  )
}

export default PanelHierarchyChart
