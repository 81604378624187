import styled from 'styled-components'

import {
  ReactTableTopSectionStyles,
  ReactTableWrapperStyles,
  ThInnerStyled,
} from '../../../components/RedaptiveReactTable7'
import { colors } from '../../../utils/themes'

export const ModalStyled = styled.div`
  backdrop-filter: blur(4px);
  color: ${colors.blue5};
  font-family: 'Public Sans', sans-serif;
  letter-spacing: 0.02em;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
`

export const ModalContentWrapperStyled = styled.div`
  width: 90%;
  height: 700px;
  max-height: 700px;
  overflow-x: auto;
  background: ${colors.white};
`

export const ModalContentStyled = styled.div`
  background: ${colors.white};
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 0 0;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  border-radius: 4px;
`

export const ModalHeaderStyled = styled.div`
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.blue14};
  margin-bottom: 20px;

  h3 {
    font-size: 12px;
    font-weight: 600;
    color: ${colors.blue3};
    text-transform: uppercase;
    margin: 0;
  }
  a {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: ${colors.blue5};
    margin: 4px 0;
    color: ${colors.blue15};
    cursor: pointer;
  }
  p {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: ${colors.blue5};
    margin: 4px 0;
    color: ${colors.blue15};
    b {
      color: ${colors.blue5};
    }
  }
`
export const ModalBodyStyled = styled.div`
  padding: 0 30px;
  overflow-y: scroll;
  height: calc(700px - 12px - 91px);
`

export const CloseButtonStyled = styled.span`
  font-size: 24px;
  color: ${colors.black};
  cursor: pointer;
`
export const WrapperStyled = styled.div`
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  height: 100%;
`
export const DateLinkStyled = styled.a`
  color: ${colors.blue15};
  font-weight: 600;
`

export const TableStyles = styled.div`
  && table {
    table-layout: auto;
    border-collapse: collapse;
    border-spacing: 0;
    border-style: 0px none transparent;
  }
  thead {
  }
  td,
  th {
    border: 0px none transparent;
    padding-left: 0px;
  }
  th {
    background: #fff !important;
    &:last-child {
      > ${ThInnerStyled} {
        position: static;
      }
    }
  }
  td {
    padding: 0;
    font-size: 14px !important;
    font-weight: 400;
  }
  tr {
    background: white !important;
    &:nth-child(odd) {
      td {
        &:not(.CalendarDay) {
          background: ${colors.gray9} !important;
        }
      }
    }
  }
  ${ReactTableWrapperStyles} {
    border-top: none;
  }
  ${ReactTableTopSectionStyles} {
    display: none;
  }
`
